<template>
   <div class="BillingList">
      <div class="filter">
         <DatePicker label="Von"
            class="noNotification"
            :options="{
               unit: 'month'
            }"
            v-model="startMonth"
            @input="onStartMonthChange" />
         <DatePicker label="Bis"
            class="noNotification"
            v-model="endMonth"
            :options="{
               unit: 'month'
            }"
            @onEndMonthChange="onEndMonthChange" />
         <Dropdown label="Suche"
            class="noNotification"
            placeholder="Rechnungsnummer"
            width="330px"
            :autocomplete="onBillNoSearch"
            :readonly="loading"
            :options="{
               autocomplete: true,
               autocompleteFnOnInit: false,
               listItemKey: 'billNo',
               listItemValueKey: 'text',
               autocompleteClearable: true,
               triangle: false
            }"
            @input="onBillNoSearchSelect" />
         <Loader v-if="loading" />
      </div>
      <template v-if="!firstLoad">
         <template v-if="bills.length > 0">
            <BillingListStats v-if="bills.length > 1"
               :bills="bills"
               :startMonth="startMonth"
               :endMonth="endMonth" />
            <BillingGrid :bills="bills"
               :showCustomerId="showCustomerId"
               pdfUrl="/api/billing/bill/pdf/"
               @deleteBill="showDeleteDialog = $event" />
         </template>
         <template v-else>
            <div class="noBills">keine Rechnungen</div>
         </template>
      </template>
      <Dialog v-if="showDeleteDialog"
         top="250px"
         transferTo="modal"
         header="Rechnung löschen"
         @close="showDeleteDialog = false"
         @outsideClick="showDeleteDialog = false">
         <div class="dlg">
            <div class="warning">
               Möchtest du die Rechnung
               <span>{{ showDeleteDialog.customerName }} {{ showDeleteDialog.billNo }}</span>
               löschen?
            </div>
            <div class="info">Mit einem Click auf Löschen werden:</div>
            <ul class="apleona">
               <li>die Rechnung gelöscht</li>
               <li>die verrechneten Leistungen auf "Fertig" zurückgesetzt</li>
            </ul>
            <div class="action">
               <Button @click="showDeleteDialog = false">Abbrechen</Button>
               <Button class="red"
                  @click="onDeleteBill()">Löschen</Button>
            </div>
         </div>
      </Dialog>
      <Dialog v-if="showServicesDialog"
         transferTo="modal"
         header="Leistungen"
         @close="showServicesDialog = false"
         @outsideClick="showServicesDialog = false">
         <ServicesGrid :services="services" />
      </Dialog>
   </div>
</template>

<script>
import BillingGrid from './grid/BillingGrid.vue';
import api from '@components/Portal/api';
import Dialog from '@components/Dialog/Dialog.vue';
import Button from '@components/Form/Button.vue';
import ServicesGrid from '../../components/services/ServicesGrid.vue'
import DatePicker from '@components/Form/DatePicker.vue';
import BillingListStats from './BillingListStats.vue';
import { DateTime, Day } from "@SyoLab/date-time";
import Loader from '@components/Tools/Loader.vue';
import Dropdown from '@components/Form/Dropdown.vue';
export default {
   name: 'BillingList',
   components: { BillingGrid, Dialog, Button, ServicesGrid, DatePicker, BillingListStats, Loader, Dropdown },
   props: {
      customer_id: { required: false },
      apiUrl: { required: false },
      showCustomerId: { required: false, default: false }
   },
   data() {
      return {
         bills: [],
         billNo: null,
         showDeleteDialog: false,
         showServicesDialog: false,
         services: [],
         startMonth: null,
         endMonth: null,
         firstLoad: true,
         loading: false
      }
   },
   methods: {
      async getData() {
         if (this.customer_id === null) {
            // services in customer app
            this.bills = []
            return
         }
         this.loading = true
         if (this.customer_id) {
            // services in customer app
            this.bills = await api.post(`${this.apiUrl}/list/bills`, {
               customer_id: this.customer_id,
               ...this.billNo ? { billNo: this.billNo } : {
                  startMonth: this.startMonth,
                  endMonth: this.endMonth
               },

            })
         } else {
            // services app
            this.bills = await api.post(`${this.apiUrl}/list/bills`, {
               ...this.billNo ? { billNo: this.billNo } : {
                  startMonth: this.startMonth,
                  endMonth: this.endMonth
               }

            })
         }
         this.firstLoad = false
         this.loading = false
      },
      onDeleteBill() {
         let bill = this.showDeleteDialog
         if (!bill) return
         let idx = this.bills.findIndex(b => b._id === bill._id)
         this.bills.splice(idx, 1)
         api.delete(`${this.apiUrl}/bill`, bill)
         this.showDeleteDialog = false
      },
      onStartMonthChange(startMonth) {
         if (startMonth == null) return
         if (startMonth > this.endMonth) {
            this.endMonth = startMonth
         }
         this.getData()
      },
      onEndMonthChange(endMonth) {
         if (endMonth == null) return
         if (endMonth < this.startMonth) {
            this.startMonth = endMonth
         }
         this.getData()
      },
      onBillNoSearch(searchString) {
         return api.post(`${this.apiUrl}/search/bills`, { billNo: searchString, customer_id: this.customer_id })
      },
      onBillNoSearchSelect(billNo) {
         this.billNo = billNo
         this.getData()
      }
   },
   computed: {
      salesVolumeMonthly() {
         return this.bills.reduce((acc, bill) => {
            let month = bill.date.substr(0, 7)
            if (!acc[month]) acc[month] = {}
            bill.revenueAccounts.forEach(record => {
               if (!acc[month][record.accountNo]) acc[month][record.accountNo] = 0
               acc[month][record.accountNo] += record.value
            })

            return acc
         }, {})
      }
   },
   watch: {
      customer_id() {
         this.getData()
      }
   },
   created() {
      this.endMonth = new Day().endOf('month').month
      this.startMonth = new Day().minus({ month: 12 }).month
      this.getData()
   }
}

</script>

<style scoped>
.BillingList {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
}

.BillingListStats {
   margin-bottom: 30px;
}

.dlg {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
   width: 400px;
   padding-top: 15px;
}

.dlg .action {
   display: flex;
   justify-content: space-around;
   padding: 15px 0;
}

.dlg .warning {
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}

.warning>span {
   color: red
}

.filter {
   display: flex;
   align-items: center;
   column-gap: 35px;
}

.noBills {
   padding: 10px 0;
}
</style>