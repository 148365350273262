<template>
   <div class="CustomerSubscriptions">
      <div class="addSubscription">
         <Dropdown class="subscriptions"
            width="330px"
            placeholder="Neues Abo auswählen"
            :options="{
               listItems: subscriptions,
               listItemKey: 'subscriptionType',
               listItemValueKey: 'name',
               returnListItem: true,
               autocomplete: true
            }"
            v-model="activeSubscription"
            @input="onAddSubscription">
         </Dropdown>
      </div>
      <div class="subscriptionList">
         <div v-if="customerSubscriptions.length == 0"
            class="subscription noSubscriptions">
            Keine Abos
         </div>
         <div class="subscription"
            v-for="subscription in customerSubscriptions"
            :key="subscription._id"
            @click="subscriptionDetailDialog = subscription">
            <div class="row top">
               <div class="left">
                  <div class="name">{{ subscription.name }}</div>
               </div>
               <div class="middle"></div>
               <div class="right">
                  <div class="startDate">{{ toEuDate(subscription.startDate) }}</div>
                  <div class="dash">-</div>
                  <div class="endDate">{{ toEuDate(subscription.endDate) }}</div>
               </div>
            </div>
            <div class="row bottom">
               <div class="left">
                  <a v-if="subscription.domain"
                     :href="`https://${subscription.domain}`"
                     target="_blank"
                     class="domain">{{ `https://${subscription.domain}` }}</a>
               </div>
               <div class="middle">
                  <div v-if="subscription.typo3Version"
                     class="typo3">V {{ subscription.typo3Version }}</div>
               </div>
               <div class="right">
                  <div class="billing">
                     <Icon :icon="$static.iconBilling" />
                  </div>
                  <div v-if="subscription.terminated"
                     class="status">beendet</div>
                  <template v-else>
                     <div v-if="subscription.monthly"
                        class="monthly">CHF {{ toNumber(subscription.monthly) }}/Mt</div>
                     <div v-if="subscription.yearly"
                        class="yearly">CHF {{ toNumber(subscription.yearly) }}/Jahr</div>
                  </template>
               </div>
            </div>
         </div>
      </div>
      <Dialog v-if="subscriptionDetailDialog"
         closeBtn="true"
         top="150px"
         class="blue"
         :header="subscriptionDetailDialog.customerName"
         @outsideClick="subscriptionDetailDialog = false"
         @close="subscriptionDetailDialog = false">
         <Typo3SubscriptionDetails v-if="subscriptionDetailDialog.subscriptionType == 'typo3'"
            :subscription="subscriptionDetailDialog"
            :config="subscriptionDetailsConfig"
            :instances="instances" />
      </Dialog>
      <Dialog v-if="addSubscriptionDialog"
         closeBtn="true"
         top="150px"
         class="blue"
         :header="addSubscriptionDialog"
         @outsideClick="addSubscriptionDialogClose"
         @close="addSubscriptionDialogClose">
         <Typo3CreateSubscription v-if="activeSubscription.subscriptionType == 'typo3'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :typo3Versions="activeSubscription.typo3Versions"
            :phpVersions="activeSubscription.phpVersions"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <WebflowCreateSubscription v-if="activeSubscription.subscriptionType == 'webflow'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <HostingCreateSubscription v-if="activeSubscription.subscriptionType == 'hosting'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <HostingEmailCreateSubscription v-if="activeSubscription.subscriptionType == 'hostingEmail'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <DomainForwardingCreateSubscription v-if="activeSubscription.subscriptionType == 'domainForwarding'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <WebflowFormsCreateSubscription v-if="activeSubscription.subscriptionType == 'webflowForms'"
            :subscription="createSubscription"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <PhpExtendedSupportCreateSubscription v-if="activeSubscription.subscriptionType == 'phpExtendedSupport'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <GenericSubscriptionCreateSubscription v-if="activeSubscription.subscriptionType == 'genericSubscription'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <WebsiteSchulenCreateSubscription v-if="activeSubscription.subscriptionType == 'websiteSchulen'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
         <WebAppCreateSubscription v-if="activeSubscription.subscriptionType == 'webApp'"
            :subscription="createSubscription"
            :instances="subscriptionsInstances"
            :customer_id="customer_id"
            :customerName="customerName"
            @save="onCreateSubscriptionSave" />
      </Dialog>
   </div>
</template>

<script>
import api from '@components/Portal/api'
import { Day } from '@SyoLab/date-time'
import Icon from "@icons/Icon.vue"
import { Documentation } from "@icons/appFabric/icons"
import Dropdown from '@components/Form/Dropdown.vue';
import Button from '@components/Form/Button.vue';
import Dialog from '@components/Dialog/Dialog.vue';

import { createWebflowSubscription, createDomainForwardingSubscription, createHostingSubscription, createHostingEmailSubscription, createWebflowFormsSubscription, createPhpExtendedSupportSubscription, createTypo3Subscription, createGenericSubscription, createWebSiteSchulenSubscription, createWebAppSubscription } from '../subscriptions/subscriptions.js'
import WebflowCreateSubscription from '../subscriptions/webflow/WebflowCreateSubscription.vue';
import DomainForwardingCreateSubscription from '../subscriptions/domainForwarding/DomainForwardingCreateSubscription.vue';
import HostingEmailCreateSubscription from '../subscriptions/hostingEmail/HostingEmailCreateSubscription.vue';
import HostingCreateSubscription from '../subscriptions/hosting/HostingCreateSubscription.vue';
import WebflowFormsCreateSubscription from '../subscriptions/webflowForms/WebflowFormsCreateSubscription.vue';
import PhpExtendedSupportCreateSubscription from '../subscriptions/phpExtendedSupport/PhpExtendedSupportCreateSubscription.vue';
import Typo3CreateSubscription from '../subscriptions/typo3/Typo3CreateSubscription.vue';
import GenericSubscriptionCreateSubscription from '../subscriptions/genericSubscription/GenericSubscriptionCreateSubscription.vue';
import WebsiteSchulenCreateSubscription from '../subscriptions/websiteSchulen/WebsiteSchulenCreateSubscription.vue';
import WebAppCreateSubscription from '../subscriptions/webAppSubscription/WebAppCreateSubscription.vue';

import Typo3SubscriptionDetails from '../subscriptions/typo3/Typo3SubscriptionDetails.vue'
export default {
   name: 'CustomerSubscriptions',
   components: { Icon, Dropdown, Button, Dialog, WebflowCreateSubscription, DomainForwardingCreateSubscription, HostingEmailCreateSubscription, HostingCreateSubscription, WebflowFormsCreateSubscription, PhpExtendedSupportCreateSubscription, Typo3CreateSubscription, GenericSubscriptionCreateSubscription, Typo3SubscriptionDetails, WebsiteSchulenCreateSubscription, WebAppCreateSubscription },
   props: {
      apiUrl: { required: true },
      customer_id: { required: true },
      customerName: { required: true },
   },
   data() {
      return {
         customerSubscriptions: [],
         subscriptions: [],
         activeSubscription: null,
         addSubscriptionDialog: false,
         createSubscription: null,
         instances: [],
         subscriptionDetailDialog: false,
      }
   },
   static: {
      iconBilling: Documentation,
   },
   methods: {
      async getSubscriptions() {
         this.instances = await api.get(`${this.apiUrl}/instances`)
         this.subscriptions = await api.get(`${this.apiUrl}/subscriptions/list`)
         this.customerSubscriptions = await api.get(`${this.apiUrl}/subscriptions/${this.customer_id}`)
      },
      toEuDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
      toNumber(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      onAddSubscription() {

         if (this.activeSubscription.subscriptionType == 'typo3') {
            this.createSubscription = createTypo3Subscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Typo3 Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'webflow') {
            this.createSubscription = createWebflowSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Webflow Hosting Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'hosting') {
            this.createSubscription = createHostingSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Hosting Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'hostingEmail') {
            this.createSubscription = createHostingEmailSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Hosting Email Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'domainForwarding') {
            this.createSubscription = createDomainForwardingSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Domain Forwarding Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'webflowForms') {
            this.createSubscription = createWebflowFormsSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Webflow Formular Upload Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'phpExtendedSupport') {
            this.createSubscription = createPhpExtendedSupportSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues PHP Extended Support Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'genericSubscription') {
            this.createSubscription = createGenericSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Manuelles Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'websiteSchulen') {
            this.createSubscription = createWebSiteSchulenSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues Website für Schulen Abo'
            return
         }

         if (this.activeSubscription.subscriptionType == 'webApp') {
            this.createSubscription = createWebAppSubscription({
               customer_id: this.customer_id,
               customerName: this.customerName,
               name: this.activeSubscription.name,
               yearly: this.activeSubscription.yearly,
               monthly: this.activeSubscription.monthly,
            })
            this.addSubscriptionDialog = 'Neues WebApp Hosting Abo'
            return
         }


      },
      addSubscriptionDialogClose() {
         this.addSubscriptionDialog = false
         this.addSubscriptionDialog = null
         this.activeSubscription = null
      },
      onCreateSubscriptionSave(subscription) {
         this.addSubscriptionDialog = false
         this.customerSubscriptions.push(this.createSubscription)
         api.put(`${this.apiUrl}/subscription`, this.createSubscription)
         this.createSubscription = null
         this.addSubscriptionDialog = null
         this.activeSubscription = null
      }
   },
   computed: {
      subscriptionsInstances() {
         if (!this.activeSubscription) return []
         return this.instances.filter(item => {
            if (!item.subscriptions) return false
            return item.subscriptions.includes(this.activeSubscription.subscriptionType)
         })
      },
      subscriptionDetailsConfig() {
         if (!this.subscriptionDetailDialog) return {}
         return this.subscriptions.find(item => item.subscriptionType == this.subscriptionDetailDialog.subscriptionType)
      },
   },
   watch: {
      customer_id() {
         this.getSubscriptions()
      }
   },
   created() {
      this.getSubscriptions()
   },
}
</script>

<style scoped>
.CustomerSubscriptions {}

.addSubscription {
   display: flex;
   align-items: center;
   column-gap: 25px;
   margin-bottom: 15px;

   .Button {
      height: 26px;
      margin-bottom: 8px;
   }
}

.subscriptionList {
   display: flex;
   flex-direction: column;
   row-gap: 15px;

   .noSubscriptions {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px;
      color: grey;
   }
}

.subscription {
   display: flex;
   flex-direction: column;
   row-gap: 18px;
   width: 650px;
   padding: 20px 40px;
   border-radius: 3px;
   border: 1px solid rgb(229, 231, 235);

   .row.top {
      justify-content: space-between;
   }

   .row {
      justify-content: space-between;

      >div {
         display: flex;
         align-items: center;
         column-gap: 5px;
      }
   }

   .row.bottom {
      font-size: 14px;
      font-weight: 400;

   }

   .name {
      font-size: 18px;
      font-weight: 500;

   }

   .period {
      display: flex;
      align-self: center;

      column-gap: 10px;
   }

   a {
      color: var(--accentBlue);
      font-weight: 500;
   }

   .billing {
      padding: 0 5px;
      cursor: pointer;
      margin-right: 5px;
   }


}
</style>