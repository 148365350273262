import { clone, shortId } from "@SyoLab/utils"

export function onBchProductUpdate(service, config, reason) {

   if (reason == 'timeEntry') {
      service.status = 'wip'
   }

   // update service totalMinutes
   service.totalMinutes = service.timeEntrys.reduce((total, service) => {
      if (service.error) return total
      if (service.notBillable) return total
      return total + service.minutes
   }, 0)

   // update service totalValue
   service.value = service.services.reduce((total, service) => {
      let value = service.value || 0
      return total + value
   }, 0)
}


// INFO: update on server in bchOrder.js also
export function getBchProductDataObject(data = {}, settings2 = {}) {

   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'bchProduct',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      notBillable: false,
      serviceType: null,
      color: null,
      accountNo: null,
      businessDomain: null,
      defaultWorkType: null,
      // estimated
      estimatedMinutes: null,
      // actual totals
      totalMinutes: 0,
      totalMinutesNonBillable: 0,
      value: 0,
      valueNonBillable: 0,
      // status
      status: null,
      assignedTo: [],
      listUntil: null, // list after billing until this date
      // serviceData
      services: [],
      timeEntrys: [],
      config: {},
      settings: {},
      createdBy: null,
      bchProductId: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}


// INFO: update on server in bchOrder.js also
export function getBchSubscriptionDataObject(data = {}, settings2 = {}) {
   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'fixedPriceTask',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      serviceType: 'bchSubscriptions',
      color: '#DCBD49',
      accountNo: '3420',
      businessDomain: 'bch',
      // bch
      bchOrder: null,
      // subscription
      startDate: null,
      endDate: null,
      // actual totals
      value: 0,
      valueNonBillable: 0,
      // status
      status: 'done',
      // serviceData
      services: [],
      config: {},
      settings: {},
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingType: 'subscription',
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}


export function getNewBchProductDataObject(data = {}, settings2 = {}) {

   switch (data.bchProductId) {
      case 'rectangle':
         data.name = 'Bildung-Schweiz.ch - Rectangle'
         data.billingText = `Online Werbung im redaktionellen Umfeld 
Laufzeit:
Sujet: `
         data.services = [{
            _id: shortId(),
            description: `Werbemittel Rectangle`,
            count: 1,
            price: '250',
            value: 250,
            discount: null
         }]
         data.value = 250
         break;

      case 'sponsoredContentPremium':
         data.name = 'Sponsored Content Premium'
         data.billingText = `Inhaltsvorschlag und Recherche.
Journalistische, suchmaschinenoptimierte Texterstellung und Publikation auf Bildung-Schweiz.ch.
Artikel-Teaser auf Start-Seite für 14 Tage
Weitere Leistungen gemäss unserem Angebot.
Thema:
Start ab: `
         data.services = [{
            _id: shortId(),
            description: `Sponsored Content Premium`,
            count: 1,
            price: '3200',
            value: 3200,
            discount: null
         }]
         data.value = 3200
         break;

      case 'sponsoredContentProfessional':
         data.name = 'Sponsored Content Professional'
         data.billingText = `Inhaltsvorschlag und Recherche.
Journalistische, suchmaschinenoptimierte Texterstellung und Publikation auf Bildung-Schweiz.ch.
Artikel-Teaser auf Start-Seite für 14 Tage
Weitere Leistungen gemäss unserem Angebot.
Thema:
Start ab: `
         data.services = [{
            _id: shortId(),
            description: `Sponsored Content Professional`,
            count: 1,
            price: '1900',
            value: 1900,
            discount: null
         }]
         data.value = 1900
         break;


      case 'sponsoredContentPublireport':
         data.name = 'Sponsored Content Publireport'
         data.billingText = `Überprüfung Ihres gelieferten PR-Textes durch unsere Redaktion und Publikation auf Bildung-Schweiz.ch.
Artikel-Teaser auf Start-Seite für 14 Tage
Weitere Leistungen gemäss unserem Angebot.
Thema:
Start ab:`
         data.services = [{
            _id: shortId(),
            description: `Sponsored Content Publireport`,
            count: 1,
            price: '1900',
            value: 1900,
            discount: null
         }]
         data.value = 1900
         break;


      case 'eventImport':
         data.name = 'Publibox.ch - Eventimport'
         //data.billingText = `Einmaliger Datenimport manuell`
         data.services = [{
            _id: shortId(),
            description: `Einmaliger Datenimport manuell`,
            count: 1,
            price: '225',
            value: 225,
            discount: null
         }]
         data.value = 225
         break

      case 'eventImportSetup':
         data.name = 'Publibox.ch - Eventimport'
         //data.billingText = `Einrichtung automatischer Datenimport`
         data.services = [{
            _id: shortId(),
            description: `Einrichtung automatischer Datenimport`,
            count: 1,
            price: '310',
            value: 310,
            discount: null
         }]
         data.value = 310
         break

      case 'newsletterWerbung':
         data.name = 'Online-Werbung Bildung-Schweiz.ch'
         data.billingText = `Publikation Ihrer Bild-/Textmeldung
Erscheinung: xx.xx.20xx
Thema: x`
         data.services = [{
            _id: shortId(),
            description: `Newsletter Bildungsanbieter`,
            count: 1,
            price: '250',
            value: 250,
            discount: null
         }]
         data.value = 250
         break

      case 'tagiAlpha':
         data.name = 'ALPHA - Der Kadermarkt - Agenda'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda im ALPHA, inkl. QR-Code`,
            count: 1,
            price: '835',
            value: 835,
            discount: null
         }]
         data.value = 835
         break


      case 'tagiAgenda':
         data.name = 'Tages-Anzeiger - Agenda Special «Bildung»'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda im Tages Anzeiger`,
            count: 1,
            price: ' 990',
            value: 990,
            discount: null
         }]
         data.value = 990
         break


      case 'nzzAudienz':
         data.name = 'audienzz - Performancegesteuerte Native Ads'
         data.billingText = `Das reichweitenstärkste digitale Native-Angebot der Schweiz. 
Click-Preis: ab CHF 1.50 
Laufzeit von xx.xx.20xx bis xx.xx.20xx`
         data.services = [{
            _id: shortId(),
            description: `Anzeige mit Bild und Text im redaktionellen Inhalt, garantiert 1700 Klicks `,
            count: 1,
            price: '2550',
            value: 2550,
            discount: null
         }]
         data.value = 2550
         break

      case '20minAgendaZurich':
         data.name = '20 Minuten - Agenda Zürich'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda 20 Minuten ZH und online abrufbar unter 20min.ch/wissen`,
            count: 1,
            price: '605',
            value: 605,
            discount: null
         }]
         data.value = 605
         break

      case '20minAgendaBern':
         data.name = '20 Minuten - Agenda Bern'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda 20 Minuten BE und online abrufbar unter 20min.ch/wissen`,
            count: 1,
            price: '420',
            value: 420,
            discount: null
         }]
         data.value = 420
         break

      case '20minAgendaBSLUSG':
         data.name = '20 Minuten - Agenda D-CH überregional (BS, LU, SG)'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda 20 Minuten BS, LU, SG und online abrufbar unter 20min.ch/wissen`,
            count: 1,
            price: '310',
            value: 310,
            discount: null
         }]
         data.value = 310
         break

      case '20minAgendaDe':
         data.name = '20 Minuten - Agenda Deutschschweiz'
         data.billingText = `Erscheinung: xx.xx.20xx
Sujet:`
         data.services = [{
            _id: shortId(),
            description: `Printeintrag in Bildungsagenda 20 Minuten Deutschschweiz und online abrufbar unter 20min.ch/wissen`,
            count: 1,
            price: '1180',
            value: 1180,
            discount: null
         }]
         data.value = 1180
         break

      case 'fixedPriceTask':
         data.name = 'Bildung-Schweiz.ch'
         data.billingText = ``
         data.services = [{
            _id: shortId(),
            description: `Eintrag`,
            count: 1,
            price: '1',
            value: 1,
            discount: null
         }]
         data.value = 1
         break

   }


   return getBchProductDataObject({
      ...data,

   }, settings2)

}