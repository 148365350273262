<template>
   <div v-if="show"
      class="AddServiceGridRow"
      :style="{ gridRow: row + 1 }">
      <div class="borderLeft"
         :style="borderLeftStyle"></div>
      <div class="addService">
         <div class="items"
            ref="items"
            :style="{ visibility: isOverflowing ? 'hidden' : null }">
            <div v-for="(service, index) in addServices"
               :key="index"
               class="btn add"
               @click="onAddClick(service)">{{ service.name }}</div>
         </div>
         <div v-if="isOverflowing"
            class="addBtn">
            <div class="btn text"
               @click="onAddServiceTypeClick()">Neues Element hinzufügen</div>
         </div>
      </div>
      <div class="action">
         <div v-if="isDeletable"
            class="btn delete"
            @click="onDelete">
            <Icon icon="iconDelete" />
         </div>
      </div>

   </div>
</template>

<script>
import CheckBox from '@components/Form/CheckBox.vue';
import { getStatusBgColor, getProjectStatus } from '../components/utils'
import Icon from "@icons/Icon.vue"
import { Delete } from "@icons/appFabric/icons"
export default {
   name: 'AddServiceGridRow',
   components: {
      CheckBox,
      Icon
   },
   props: {
      config: { required: true },
      addServiceList: { required: true },
      service: { required: true },
      row: { required: true },
   },
   data() {
      return {
         isOverflowing: false
      }
   },
   static: {
      iconDelete: Delete
   },
   methods: {
      onDelete() {
         this.$emit('deleteProject')
      },
      onAddClick(service) {
         let data = {
            type: service.type,
            project_id: this.project.project_id,
            projectName: this.project.projectName,
            customer_id: this.project.customer_id,
            customerName: this.project.customerName,
            businessDomain: this.project.businessDomain,
            accountNo: this.project.accountNo,
            serviceType: this.project.serviceType,
            settings: this.project.settings || {},
         }
         this.$emit('addService', data)
      },
      onItemsSizeChange(e) {
         let target = e[0].target
         let height = target.clientHeight
         this.isOverflowing = height > 34 ? true : false
      },
      onAddServiceTypeClick() {
         let data = {
            project_id: this.project.project_id,
            projectName: this.project.projectName,
            customer_id: this.project.customer_id,
            customerName: this.project.customerName,
            businessDomain: this.project.businessDomain,
            accountNo: this.project.accountNo,
            serviceType: this.project.serviceType,
            settings: this.project.settings || {},
         }
         this.$emit('addServiceType', data)
      }
   },
   computed: {
      borderLeftStyle() {
         let color = this.service.color || this.config.defaultServiceColor
         return {
            gridRow: this.row + 1,
            backgroundColor: color,
            borderTopColor: color,
            borderBottomColor: color,
         }
      },
      isDeletable() {
         if (this.config.mode == 'myTasks') return false
         return this.service._project._services.length == 0
      },
      project() {
         return this.service._project
      },
      addServices() {
         let serviceType = this.project.serviceType
         let services = this.addServiceList.find(item => item.serviceType == serviceType)?.services || []
         return services.filter(item => item.type != 'project')
      },
      show() {
         if (this.isDeletable) return true
         if (this.isOverflowing) return true
         return this.addServices.length > 0

      }
   },
   watch: {
      show(value) {
         if (value) {
            new ResizeObserver(this.onItemsSizeChange).observe(this.$refs.items)
         }
      }
   },
   mounted() {
      if (this.show) {
         new ResizeObserver(this.onItemsSizeChange).observe(this.$refs.items)
      }
   }
}
</script>

<style scoped>
.AddServiceGridRow {
   display: grid;
   grid-column: 1/-1;
   grid-template-columns: subgrid;
   grid-template-rows: subgrid;
   background-color: var(--rowBgColor);
}

.AddServiceGridRow>* {
   display: flex;
   align-items: center;
   height: var(--rowHeight);
   padding: 3px 0;
   border-top: 1px solid var(--borderColor);
   border-bottom: 1px solid var(--borderColor);
}

.AddServiceGridRow>*:last-child {
   border-right: 1px solid var(--borderColor);
   ;
}

.borderLeft {
   grid-column: borderLeft;
   background-color: var(--borderLeftColor);
   border-top-color: var(--borderLeftColor);
   border-bottom-color: var(--borderLeftColor);

}

.addService {
   grid-column: 2 / -1;
   padding-left: 10px;
   position: relative;
}

.addService .items {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
}

.btn.add {
   font-weight: 500;
   font-size: 14px;
   text-transform: uppercase;
   color: var(--accentBlue);
   display: flex;
   align-items: center;
   padding: 1px 8px;
   cursor: pointer;
   border-radius: 3px;
}


.action {
   grid-column: action;
   display: flex;
   align-items: center;
   justify-content: center;
}

.addBtn {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   padding: 3px 0;
}
</style>