import { ObjectId } from '@SyoLab/utils'
import { Day, Timestamp, } from '@SyoLab/date-time'
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()

export function createWebflowSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: 'webflow',
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'webflow',
      hostingType: 'webflow', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createDomainForwardingSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'domainForwarding',
      //hostingType: 'domainForwarding', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createHostingEmailSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'hostingEmail',
      hostingType: 'hostingEmail', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createPhpExtendedSupportSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'phpExtendedSupport',
      //hostingType: 'phpExtendedSupport', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createWebflowFormsSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: 'webflow',
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'webflowForms',
      //hostingType: 'webflowForms', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createTypo3Subscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: undefined,
      typo3Version: undefined,
      phpVersion: undefined,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      type: 'subscription',
      subscriptionType: 'typo3',
      hostingType: 'typo3', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createHostingSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'hosting',
      hostingType: 'hosting', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createGenericSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'genericSubscription',
      //hostingType: 'domainForwarding', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createWebSiteSchulenSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: 'webflow',
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'websiteSchulen',
      hostingType: 'webflow', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}

export function createWebAppSubscription(data) {

   return {
      _id: ObjectId(),
      name: null,
      customer_id: null,
      customerName: null,
      instance: null,
      domain: null,
      since: null,
      yearly: null,
      monthly: null,
      startDate: null,
      endDate: null,
      terminated: false,
      comment: null,
      type: 'subscription',
      subscriptionType: 'webApp',
      hostingType: 'webApp', // only hosting has this property
      businessDomain: 'web',
      accountNo: '3432',
      color: '#c858f9',
      createdBy: portalState.user.userId,
      createdTimeStamp: Timestamp(),
      ...data
   }
}