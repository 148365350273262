<template>
   <div id="App"
      class="Finances">
      <Tabs class="large"
         :tabs="tabs"
         :activeTab="activeTab"
         @nav="activeTab = $event" />
      <CreditTransactions v-if="activeTab?._id == 1" />
   </div>
</template>

<script>
import Tabs from '@components/Navigation/Tabs.vue';

import CreditTransactions from './CreditTransactions.vue'
export default {
   name: 'Finances',
   components: { Tabs, CreditTransactions, },
   async setup(props, context) { },
   props: {},
   data() {
      return {
         tabs: [
            { _id: 1, text: 'Zahlungseingänge', },
            //{ _id: 2, text: 'Zahlungsausgänge', },
         ],
         activeTab: {},
      }
   },
   methods: {},
   computed: {},
}
</script>

<style scoped>
.Finances {}

.CreditTransactions,
.TransactionUpload {
   margin-top: 15px;
}
</style>