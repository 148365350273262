<template>
   <div class="CreditTransactions">
      <div class="uploadRow">
         <FileUpload accept=".pdf"
            url="finances/upload/ukb/pdf"
            @select="isUploading = true"
            @success="onUpload">UKB Kontoauszug hochladen</FileUpload>
         <div v-if="isUploading"
            class="uploadLoader">
            <Loader />
         </div>
         <div v-if="!isUploading && lastTransaction"
            class="lastTransaction">letzte Transaktion: {{ toEuDateShort(lastTransaction) }}</div>
      </div>
      <div class="filter">
         <DatePicker label="Von"
            class="noNotification"
            :options="{
               unit: 'month'
            }"
            v-model="startMonth"
            @input="onStartMonthChange" />
         <DatePicker label="Bis"
            class="noNotification"
            v-model="endMonth"
            :options="{
               unit: 'month'
            }"
            @onEndMonthChange="onEndMonthChange" />

      </div>
      <div class="checkBoxRow">
         <CheckBox v-model="matched"
            @input="onCheckBox('matched', $event)">Zugeordnete</CheckBox>
         <CheckBox v-model="notMatched"
            @input="onCheckBox('matched', $event)">Nicht zugeordnete</CheckBox>
         <CheckBox v-model="typeRefund"
            @input="onCheckBox('typeRefund', $event)">Gutschriften</CheckBox>
         <CheckBox v-model="typeOther"
            @input="onCheckBox('typeOther', $event)">Andere</CheckBox>
      </div>
      <div class="grid">
         <div class="gridRow">
            <div class="creditor header">Bezeichnung</div>
            <div class="billNo header">Rechnung</div>
            <div class="date header">Datum</div>
            <div class="amount header">Betrag</div>
         </div>
         <div v-for="transaction in gridTransactions"
            :key="transaction._id"
            class="gridRow">
            <div class="creditor"
               @click="onTransactionClick(transaction)">{{ transaction.creditor[0] }}</div>
            <div class="billNo"
               @click="onTransactionClick(transaction)">{{ transaction.billNo }}</div>
            <div class="date"
               @click="onTransactionClick(transaction)">{{ toEuDate(transaction.date) }}</div>
            <div class="amount"
               @click="onTransactionClick(transaction)">{{ toFormatNumber(transaction.amount) }}</div>
         </div>
      </div>
      <Dialog v-if="transactionDialog"
         transferTo="modal"
         top="150px"
         class="blue"
         left=" 200px"
         header="Zahlung"
         @close="transactionDialog = false"
         @outsideClick="transactionDialog = false">
         <CreditTransactionsDialog :transaction="transactionDialog"
            @close="transactionDialog = null"
            @save="onTransactionDialogSave" />
      </Dialog>
   </div>
</template>

<script>
import api from '@components/Portal/api'
import { Day } from '@SyoLab/date-time'
import DatePicker from '@components/Form/DatePicker.vue';
import Loader from '@components/Tools/Loader.vue';
import CheckBox from '@components/Form/CheckBox.vue';
import Dialog from '@components/Dialog/Dialog.vue';
import CreditTransactionsDialog from './CreditTransactionsDialog.vue';
import FileUpload from '@components/Form/FileUpload.vue';
import eventBus from '@components/Portal/eventBus';
export default {
   name: 'CreditTransactions',
   components: { DatePicker, Loader, CheckBox, Dialog, CreditTransactionsDialog, FileUpload },
   async setup(props, context) { },
   props: {},
   data() {
      return {
         transactions: [],
         startMonth: null,
         endMonth: null,
         matched: false,
         notMatched: true,
         typeRefund: false,
         typeOther: false,
         transactionDialog: null,
         lastTransaction: null,
         isUploading: false
      }
   },

   methods: {
      async getData() {
         this.transactions = await api.post('finances/transactions', {
            startMonth: this.startMonth,
            endMonth: this.endMonth,
         })
         this.lastTransactionUpdate()
      },
      async lastTransactionUpdate() {
         let lastTransaction = await api.get('finances/transactions/last')
         this.lastTransaction = lastTransaction ? lastTransaction.date : null
      },
      toFormatNumber(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      toEuDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
      toEuDateShort(date) {
         if (!date) return null
         return new Day(date).toFormat('dd.MM.yy')
      },
      onStartMonthChange(startMonth) {
         if (startMonth == null) return
         if (startMonth > this.endMonth) {
            this.endMonth = startMonth
         }
         this.getData()
      },
      onEndMonthChange(endMonth) {
         if (endMonth == null) return
         if (endMonth < this.startMonth) {
            this.startMonth = endMonth
         }
         this.getData()
      },
      onTransactionClick(transaction) {
         this.transactionDialog = structuredClone(transaction)
      },
      onTransactionDialogSave(transaction) {

         let transactionIdx = this.transactions.findIndex(b => b._id === transaction._id)

         // check for changed bills
         let billTransactionsDeleted = []
         if (this.transactions[transactionIdx].bills) {
            billTransactionsDeleted = this.transactions[transactionIdx].bills.filter(t => {
               if (!transaction.bills) return true
               return !transaction.bills.find(b => b.billNo === t.billNo)
            })
         }

         this.transactions.splice(transactionIdx, 1, transaction)
         api.post('finances/transaction', {
            transaction,
            billTransactionsDeleted,
         })
         this.transactionDialog = false
      },
      onUpload(data) {
         eventBus.emit('notify.info', `${data.processed} Transaktionen verarbeitet`, { timeout: 5000 })
         eventBus.emit('notify.info', `${data.transactions} Transaktionen eingelesen`, { timeout: 5000 })
         this.isUploading = false
         this.lastTransactionUpdate()
         this.getData()
      },
      onCheckBox(type, value) {
         if (value && type == 'typeOther') {
            this.matched = false
            this.notMatched = false
            this.typeRefund = false
         }
         if (value && type == 'typeRefund') {
            this.matched = false
            this.notMatched = false
            this.typeOther = false
         }
         if (value && type == 'matched') {
            this.typeOther = false
            this.typeRefund = false
         }

      }


   },
   computed: {
      gridTransactions() {
         return this.transactions.filter(transaction => {
            if (transaction.creditType == 'other' && !this.typeOther) return false
            if (transaction.creditType == 'refund' && !this.typeRefund) return false
            if (transaction.creditType == 'cash' && !this.typeCash) return false
            if (transaction.creditType == 'billPayment' || !transaction.creditType) {
               if (!this.matched && !this.notMatched) return false
               if (this.matched && this.notMatched) return true
               if (!transaction.matched && this.matched) return false
               if (transaction.matched && this.notMatched) return false
            }
            return true
         })
      },

   },
   created() {
      this.endMonth = new Day().endOf('month').month
      this.startMonth = new Day().minus({ month: 12 }).month
      this.getData()
   }
}
</script>

<style scoped>
.CreditTransactions {
   padding-top: 15px;
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.grid {
   display: grid;
   grid-template-columns: [creditor] max-content [billNo] max-content [date] max-content [amount] max-content;

   .gridRow {
      display: contents;
      cursor: pointer;

      &hover {
         background-color: var(--activeHover);
      }

      >div {
         padding: 7px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
         display: flex;
         align-items: center;
      }

      .amount {
         justify-content: flex-end;
      }
   }

   .gridRow>.header {
      padding: 5px 10px 13px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
   }

}

.filter {
   display: flex;
   align-items: center;
   column-gap: 35px;
}

.checkBoxRow {
   display: flex;
   align-items: center;
   column-gap: 35px;
   padding: 15px 0;
}

.uploadRow {
   display: flex;
   align-items: center;
}

.uploadRow {
   padding-bottom: 5px;
}

.FileUpload {
   border: 1px solid grey;
   border-radius: 5px;
   padding: 2px 5px;
   cursor: pointer;
}

.lastTransaction,
.uploadLoader {
   display: flex;
   padding-left: 25px;
}
</style>