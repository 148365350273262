<template>
   <div class="SubscriptionServiceGridRow"
      :title="title"
      :style="{ gridRow: row + 1 }"
      :class="{ error: hasError }"
      @click="onRowClick">
      <div class="borderLeft"
         :style="borderLeftStyle"></div>
      <div class="taskName"
         :style="{ gridRow: row + 1 }">{{ service.name }}</div>

      <div class="status"
         :style="{ gridRow: row + 1 }">
         <Status v-if="showRight == 'status'"
            :displayText="statusDisplayText"
            :afterText="statusAfterText"
            v-model="service.status"
            :readonly="true"
            :exclude="['billed', 'paid']"
            @input="$emit('serviceUpdate', $event)" />
         <div v-if="showRight == 'billingTotal'"
            class="billingTotal">
            {{ serviceValue }}
         </div>
      </div>
      <div class="action"
         ref="action"
         :style="{ gridRow: row + 1 }">
         <CheckBox v-if="isSelectable"
            class="small"
            :value="isSelected"
            @input="$emit('select', service, $event)" />
         <ServiceGridBillIcon v-else-if="service.billNo"
            :config="config"
            :service="service" />
         <MenuMore v-else-if="menuMoreItems.length > 0"
            orientation="right">
            <div v-for="(menuItem, index) in menuMoreItems"
               :key="index"
               class="menuItem"
               @click="onMenuItemClick(menuItem)">
               <Icon :class="menuItem.icon"
                  :icon="$static[menuItem.icon]" />
               <span>{{ menuItem.text }}</span>
            </div>
         </MenuMore>
      </div>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import { Bill, Up, Down, Delete } from "@icons/appFabric/icons"
import CheckBox from '@components/Form/CheckBox.vue';
import Status from '../components/Status.vue';
import MenuMore from "@components/Navigation/MenuMore.vue";
import ServiceGridBillIcon from "../components/ServiceGridBillIcon.vue"
import AssignedTo from "../components/AssignedTo.vue"
import TimeToggle from "../components/TimeToggle.vue"
import { minutes } from 'src/utils/utils'

export default {
   name: 'SubscriptionServiceGridRow',
   components: {
      CheckBox,
      Status,
      Icon,
      MenuMore,
      ServiceGridBillIcon,
      AssignedTo,
      TimeToggle
   },
   props: {
      service: { required: true },
      row: { required: true },
      config: { required: true, },
      admin: { required: false, default: false },
      selectable: { required: false, default: false },
      selectedCustomer_id: { required: false, default: false },
      selectedServices: { required: false, default: false },
      readonly: { required: false, default: false },
      billingPdfUrl: { required: false, default: '' },
      toggleItems: { required: false },
      taskTotalCHF: { required: false, default: false },
   },
   data() {
      return {}
   },
   static: {
      iconBill: Bill,
      arrowUp: Up,
      arrowDown: Down,
      iconDelete: Delete
   },
   methods: {
      onRowClick(e) {
         if (this.$refs.action.contains(e.target)) return
         this.$emit('click', this.service)
      },
      onMenuItemClick(menuItem) {
         if (menuItem.operation == 'delete') {
            this.$emit('delete')
         }
         if (menuItem.operation == 'move') {
            this.$emit('move', menuItem.move)
         }
      }
   },
   computed: {
      title() {
         let serviceType = this.config.addServiceList.find(item => item.serviceType === this.service.serviceType)
         return serviceType?.value || 'Festpreis - Task'
      },
      borderLeftStyle() {
         let color = this.service.color || this.config.defaultServiceColor
         return {
            gridRow: this.row + 1,
            backgroundColor: color,
            borderTopColor: color,
            borderBottomColor: color,
         }
      },
      isSelectable() {
         if (this.isReadonly) return false
         if (!this.selectable) return false
         if (this.service.services.length == 0) return false
         if (!this.service.value) return false
         if (this.selectedCustomer_id && this.selectedCustomer_id != this.service.customer_id) return false
         return true
      },
      isSelected() {
         return this.selectedServices.includes(this.service._id) ? true : false
      },
      isReadonly() {
         if (this.readonly) return true
         if (this.service.billNo) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         return false
      },
      menuMoreItems() {
         if (this.isReadonly || this.service.billNo) return []

         let menuItems = []
         if (this.service.project_id) {
            // clone project services
            let services = [...this.service._project?._services || []]
            // remove billed services
            services = services.filter(s => !s.billNo)
            // get index of current service
            let idx = services.findIndex(s => s._id == this.service._id)
            if (idx > 0) {
               menuItems.push({ icon: 'arrowUp', text: 'Verschieben', operation: 'move', move: 'prev' })
            }
            if (idx > -1 && idx < services.length - 1) {
               menuItems.push({ icon: 'arrowDown', text: 'Verschieben', operation: 'move', move: 'next' })
            }

         }
         if (this.config.admin) {
            menuItems.push({ icon: 'iconDelete', text: 'Löschen', operation: 'delete' })
         } else if (!this.isReadonly && this.service.services.length == 0) {
            menuItems.push({ icon: 'iconDelete', text: 'Löschen', operation: 'delete' })
         }
         return menuItems
      },
      showRight() {
         if (this.config.showBilling === false) return 'status'
         if (this.service.billNo) return 'billingTotal'
         return 'status'
      },
      serviceValue() {
         let value = this.service.value.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
         return `CHF ${value}`
      },
      showMiddle() {
         if (this.config.mode == 'myTasks' && !this.isReadonly) return 'toggle'
         return 'assignedTo'
      },
      hasError() {
         return false
      },
      statusDisplayText() {
         if (this.taskTotalCHF) {
            return this.serviceValue
         }
         return null
      },
      statusAfterText() {
         if (!this.taskTotalCHF && this.service.totalMinutes) {
            if (this.service.estimatedMinutes > 0) {
               let percentage = this.service.totalMinutes / this.service.estimatedMinutes * 100
               percentage = Math.round(percentage)
               return `${percentage}%`
            }
            if (this.service.totalMinutes > 0) {
               return `+${minutes.toFormatDecimal(this.service.totalMinutes)}`
            }
         }
         return null
      },
   },
}
</script>

<style scoped>
.SubscriptionServiceGridRow {
   display: grid;
   grid-column: 1/-1;
   grid-template-columns: subgrid;
   grid-template-rows: subgrid;
   cursor: pointer;
   background-color: var(--rowBgColor);
}

.SubscriptionServiceGridRow.error {
   background-color: #f8d7da;
}

.SubscriptionServiceGridRow>* {
   display: flex;
   align-items: center;
   height: var(--rowHeight);
   padding: 3px 0;
   border-top: 1px solid var(--borderColor);
   border-bottom: 1px solid var(--borderColor);
}

.SubscriptionServiceGridRow>*:last-child {
   border-right: 1px solid var(--borderColor);
   ;
}

.borderLeft {
   grid-column: borderLeft;
   background-color: var(--borderLeftColor);
   border-top-color: var(--borderLeftColor);
   border-bottom-color: var(--borderLeftColor);
}

.taskName {
   grid-column: left;
   grid-column-end: 4;
   padding-left: 10px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}


.select {
   grid-column: select;
   padding-left: 3px;
}

.middle {
   grid-column: middle;
   padding: 0;
}

.SubscriptionServiceGridRow .status {
   grid-column: right;
   display: flex;
   align-items: center;
   padding: 0;
   border: 0;
}

.billingTotal {
   padding-right: 10px;
   text-align: right;
   width: 100%;
}

.action {
   grid-column: action;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: default;
}

.MenuMore .menuItem {
   display: flex;
   column-gap: 7px;
}

.menuItem .iconDelete {
   font-size: 15px;
}

.menuItem .arrowUp,
.menuItem .arrowDown {
   font-size: 13px;
}

.assignedToList {
   display: flex;
   align-items: center;
   width: 100%;
   overflow: hidden;
}

.timeToggle {
   display: flex;
   align-items: flex-start;
   width: 100%;
   height: 100%;
}

.timeToggle .TimeToggle {
   width: 100%;
}
</style>