<template>
   <div class="BillingGrid">
      <div class="grid"
         :style="gridStyle">
         <div class="gridRow header">
            <div class="customerName">Kunde</div>
            <div class="billNo">Rechnung</div>
            <div class="date">Datum</div>
            <div class="amount">Betrag</div>
            <div class="billedBy">Verrechnet durch</div>
            <div v-if="showCustomerId"
               class="customerId">Kundennummer</div>
            <div class="status">Status</div>
            <div class="paymentDays">Tage</div>
            <div class="open">PDF</div>
            <div class="services">Leist.</div>
            <div class="action">Aktion</div>
         </div>
         <div v-if="bills.length == 0"
            class="noData"> Keine Daten</div>
         <div class="gridRow record"
            v-for="bill in bills"
            :key="bill.billNo">
            <div class="customerName">{{ bill.customerName }}</div>
            <div class="billNo">{{ bill.billNo }}</div>
            <div class="date">{{ toEuDate(bill.date) }}</div>
            <div class="amount">{{ toAmount(bill.value) }}</div>
            <div class="billedBy">{{ bill.billedBy }}</div>
            <div v-if="showCustomerId"
               class="customerId">{{ bill.customerId }}</div>
            <div class="status">
               <div v-if="bill.payed"
                  class="statusItem payed">bezahlt</div>
               <div v-else-if="bill.payment && bill.payment > 0"
                  class="statusItem partial">
                  {{ partialPayment(bill) }}
                  <div class="payments">
                     <div class="payment">
                        <div class="date">{{ toEuDate(bill.date) }}</div>
                        <div class="amount">{{ toAmount(bill.payment) }}</div>
                     </div>
                  </div>
               </div>
               <div v-else
                  class="statusItem open">offen</div>
            </div>
            <div class="paymentDays">{{ getPaymentDays(bill) }}</div>
            <div class="open">
               <div v-if="pdfUrl"
                  class="btn hover"
                  @click="onPdfShow(bill)">
                  <Icon icon="iconPdf" />
               </div>
            </div>
            <div class="services">
               <div v-if="bill.services && bill.services.length > 0"
                  class="btn hover">
                  <Icon icon="iconOpen" />
               </div>
            </div>
            <div class="action">
               <MenuMore v-if="showMenuMore(bill)"
                  class="small"
                  orientation="right">
                  <div class="delete"
                     @click="$emit('deleteBill', bill)">
                     <Icon :icon="$static.iconDelete" />Löschen
                  </div>
               </MenuMore>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
import { Day } from "@SyoLab/date-time"
import Icon from "@icons/Icon.vue"
import { OpenInNewWindow, Delete, PDF } from "@icons/appFabric/icons"
import MenuMore from '@components/Navigation/MenuMore.vue'
import { popupOpen } from "../../../components/services/components/utils"
export default {
   name: 'BillingGrid',
   components: { Icon, MenuMore },
   props: {
      bills: { required: true },
      readonly: { type: Boolean, default: false },
      pdfUrl: { required: true },
      showCustomerId: { type: Boolean, default: false }
   },
   data() {
      return {
      }
   },
   static: {
      iconPdf: PDF,
      iconOpen: OpenInNewWindow,
      iconDelete: Delete,
   },
   methods: {
      toEuDate(date) {
         return new Day(date).toEuDate()
      },
      toAmount(value) {
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      onPdfShow(bill) {
         popupOpen(window, `${this.pdfUrl}${bill.pdf}`, 'bill', { height: 1200, width: 1200 })
      },
      showMenuMore(bill) {
         if (this.readonly) return false
         return bill.status ? false : true
      },
      partialPayment(bill) {
         let diff = bill.value - bill.payment
         return this.toAmount(diff)
      },
      getPaymentDays(bill) {
         if (bill.paymentDays || bill.paymentDays == 0) return bill.paymentDays
         return new Day().diff(bill.date, 'days')
      }
   },
   computed: {
      gridStyle() {
         let gridTemplateColumns = '[customerName] 270px  [billNo] max-content [date] 95px [amount] 90px [billedBy] 135px  [status] 75px [paymentDays] 65px [open] 55px [services] 55px [action] 55px'
         if (this.showCustomerId) {
            gridTemplateColumns = '[customerName] 270px [billNo] max-content [date] 95px  [amount] 90px [billedBy] 135px [customerId] max-content [status] 75px [paymentDays] 55px [open] 55px [services] 55px [action] 55px'
         }
         return {
            display: 'grid',
            gridTemplateColumns
         }
      }
   },
}
</script>

<style scoped>
.BillingGrid {}

.header>div {
   padding: 5px 10px 13px 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   cursor: pointer;
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
}

.record>div {
   padding: 7px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   display: flex;
   align-items: center;
}

.gridRow {
   display: contents;
}

.gridRow .customerName {
   grid-column: customerName;
   display: block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.grid .customerId {
   grid-column: customerId;
}

.gridRow .date {
   grid-column: date;
}

.gridRow .amount {
   grid-column: amount;
   justify-content: flex-end;
}

.gridRow .billedBy {
   grid-column: billedBy;
}

.gridRow .billNo {
   grid-column: billNo;
}

.gridRow .status {
   grid-column: status;
}

.gridRow .services {
   grid-column: services;
   justify-content: center;
}

.gridRow .paymentDays {
   justify-content: center;
}

.gridRow .open {
   grid-column: open;
   justify-content: center;
}

.gridRow .open .btn,
.gridRow .services .btn {
   padding: 5px;
}

.gridRow .action {
   grid-column: action;
   justify-content: center;
}

.action .delete {
   display: flex;
   align-items: center;
   column-gap: 5px;
}

.statusItem {
   display: inline-flex;
   align-items: center;
   flex-shrink: 1;
   min-width: 0px;
   max-width: 100%;
   height: 20px;
   border-radius: 10px;
   padding: 2px 7px;
   font-size: 12px;
   margin: 0px;
   user-select: none;
}

.statusItem.open {
   background-color: #d3e5ef;
   color: #183347;
   border: 1px solid #d3e5ef;
}

.statusItem.payed {
   background-color: #dbeddb;
   color: #1c3829;
   border: 1px solid #dbeddb;
}

.statusItem.partial {
   background-color: #ffdad7;
   color: #1c3829;
   border: 1px solid #ffdad7;
   position: relative;
   cursor: pointer;

   .payments {
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -100%);
      display: none;
      flex-direction: column;
      background-color: white;
      border: 1px solid grey;
      padding: 5px;

      .payment {
         display: flex;
         column-gap: 15px;
      }
   }

   &:hover {
      .payments {
         display: flex;
      }
   }
}



.noData {
   grid-column: 1 / -1;
   padding: 15px;
   font-size: 12px;
   color: rgba(0, 0, 0, 0.7);
   text-align: center;
}
</style>