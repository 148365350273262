<template>
   <div class="ProjectEdit">
      <template v-if="!showProjectSettings">
         <div class="task">
            <div class="title">{{ service.customerName }} </div>
            <div class="header">
               <div class="name">
                  <InputBasic class="name"
                     ref="serviceName"
                     :readonly="isReadonly"
                     v-model="service.projectName"
                     @enter="onEditEnd"
                     @input="onServiceChange" />
               </div>
               <div class="status">
                  <Status :readonly="true"
                     v-model="service.status"
                     @input="onServiceChange" />
               </div>
               <div v-if="config.showBilling"
                  class="settings"
                  @click="onShowSettings(true)">
                  <Icon icon="iconSettings" />
               </div>
            </div>
         </div>
         <div v-if="billingShow"
            class="billing">
            <div class="title">Rechnung</div>
            <ProjectBilling :service="service"
               :readonly="isReadonly"
               mode="edit"
               :config="config"
               @update="onServiceChange" />
         </div>
         <div v-if="!isReadonly"
            class="action">
            <Button v-if="showDeleteBtn"
               class="red"
               @click="$emit('delete')">Löschen</Button>
            <Button class="undo"
               :enabled="undoEnabled"
               @click="undoChanges">
               <div class="undo">
                  <span>Rückgängig</span>
                  <Icon :icon="$static.iconUndo" />
               </div>
            </Button>
            <Button @click="onEditEnd">Schliessen</Button>
         </div>
      </template>
      <template v-if="showProjectSettings">
         <ServiceSettings :config="config"
            :readonly="isReadonly"
            :service="service"
            :showBillingRates="true"
            @close="onShowSettings(false)" />
      </template>
   </div>

</template>


<script>
import ProjectBilling from './ProjectBilling.vue';

import Dropdown from '@components/Form/Dropdown.vue';
import Input from '@components/Form/Input.vue';
import InputBasic from '@components/Form/InputBasic.vue';
import CheckBox from '@components/Form/CheckBox.vue';
import { minutes } from 'src/utils/utils'
import api from "@components/Portal/api.js"
import Icon from "@icons/Icon.vue"
import { Search, Settings, Undo } from "@icons/appFabric/icons"
import Button from '@components/Form/Button.vue'
import Status from '../components/Status.vue';
import InputItems from '../components/InputItems.vue';
import ServiceSettings from '../components/ServiceSettings.vue'
import { editServiceUpdate } from '../components/utils.js'
import { getProjectDataObject } from './project'

function traverseMapToRecords(mapPointer, index, records) {
   if (Array.isArray(mapPointer)) {
      records.push({ index, timeEntrys: mapPointer })
   }
   else {
      for (let key in mapPointer) {
         traverseMapToRecords(mapPointer[key], `${index}.${key}`, records)
      }
   }
}

function serviceRecordTemplate(settings) {
   let record = { value: 0, text: null }
   if (settings.showDate) record.date = ''
   if (settings.showEmployee) {
      record.employee_id = null
      record.employee = null
   }
   if (settings.showHourlyRate) record.hourlyRate = null
   if (settings.showFromTo) {
      record.from = null
      record.to = null
   }
   if (settings.showDuration) record.minutes = 0
   return {
      fieldNames: Object.keys(record),
      serviceRecord: record
   }
}


export default {
   name: 'ProjectEdit',
   components: {
      ProjectBilling,
      Dropdown,
      Input,
      InputBasic,
      Icon,
      Button,
      CheckBox,
      Status,
      InputItems,
      ServiceSettings
   },
   props: {
      projectData: { required: true, type: Object },
      config: { required: true, type: Object },
      readonly: { required: false, default: false },
      services: { required: true, type: Array },
   },
   data() {
      return {
         customerSearchString: null,
         projectSearchString: null,
         service: {},
         showProjectSettings: false,
         undoEnabled: false,
      }
   },
   static: {
      iconSearch: Search,
      iconSettings: Settings,
      iconUndo: Undo,
   },
   methods: {
      async onCustomerSearch(customerSearchString) {
         if (!customerSearchString) return []
         return await api.get(`customer/customerSearch/${customerSearchString}`)
      },
      onCustomerSelect(selectedItem) {
         if (this.service.customer_id != selectedItem._id) {
            this.service.project_id = null
            this.service.projectName = null
         }
         this.service.customer_id = selectedItem._id
         this.service.customerName = selectedItem.name
      },
      async onProjectSearch(projectSearchString) {
         if (!projectSearchString) return []
         return await api.get(`customer/customerSearch/${projectSearchString}`)
      },
      onProjectSelect(selectedItem) {
         this.service.project_id = selectedItem._id
         this.service.projectName = selectedItem.name
      },
      formatToDecimal(value) {
         return value ? minutes.toFormatDecimal(value) : null
      },
      onEstimatedMinutesChange(value) {
         this.service.estimatedMinutes = minutes.parse(value)
      },
      onSettingsUpdate() {
         this.service.update()
      },
      onEditEnd() {
         this.$emit('editEnd', this.service)
      },
      onShowSettings(value) {
         setTimeout(() => {
            this.showProjectSettings = value
         }, 1)
      },
      onServiceChange() {
         this.undoEnabled = true
      },
      undoChanges() {
         this.service = getProjectDataObject(this.projectData)
         this.undoEnabled = false
      }
   },
   computed: {
      showDeleteBtn() {
         return this.services.filter(s => {
            return s.project_id == this.service.project_id && s.type != 'project'
         }) == 0
      },
      settings() {
         return this.service.settings
      },
      isReadonly() {
         if (this.readonly) return true
         if (this.service.status == 'billed') return true
         if (this.service.status == 'payed') return true
         return false
      },
      billShow() {
         if (this.service.status == 'billed') return true
         if (this.service.billNo) return true
         if (this.selectable) return true
         return false
      },
      billingShow() {
         return this.config.showBilling ? true : false

      },
   },
   created() {
      this.service = getProjectDataObject(this.projectData)
   },
   watch: {
      taskData: {
         handler() {
            editServiceUpdate(this.projectData, this.service,)
         },
         deep: true
      }
   },
   mounted() {
      if (this.service?._isNew) {
         this.$refs.serviceName.select()
      }
   }
}
</script>

<style scoped>
.ProjectEdit {
   display: flex;
   flex-direction: column;
   row-gap: 25px;
   padding-top: 10px;
   width: 900px;
}

.ProjectBilling {
   border: 1px solid #d3d2d2;
   border-radius: 9px;
   padding: 15px;
}

.ServiceSettings {
   align-self: center;
}


.task {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.status {
   display: flex;
   align-items: center;
}

.header {
   display: flex;
   justify-content: space-between;
   column-gap: 65px;

}

.header>.name {
   width: 550px;
   height: 40px;
   padding-left: 2px;
   background-color: #e9e9e9;
   display: flex;
   align-items: center;
   border-radius: 3px;
}

.header>.name .Input {
   width: 100%;
   height: 100%;
}

.assignedToRow {
   display: flex;
   column-gap: 65px;
   margin-top: 5px;
}

.assignedToRow .right {
   display: flex;
   column-gap: 25px;
}

.assignedTo {
   display: flex;
   flex-direction: column;
   row-gap: 2px;
   margin-top: 3px;
}

.assignedToList {
   position: relative;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   column-gap: 20px;
   width: 550px;
   min-height: 36px;
   margin-bottom: 16px;
}

.assignedToList::after {
   content: ' ';
   position: absolute;
   bottom: 0px;
   left: 0;
   width: 100%;
   height: 1px;
   border-bottom: 1px solid #619cca;
}

.title {
   font-size: 22px;
   font-weight: 300;
}

.billing {
   display: flex;
   flex-direction: column;
   row-gap: 15px;

}

.billing .settings {
   display: flex;
   flex-wrap: wrap;
   column-gap: 25px;
   padding-bottom: 5px;
}

.timeEntrys {
   display: flex;
   flex-direction: column;
   row-gap: 15px;
}

.Dropdown.customerSearch,
.Dropdown.projectSearch {
   width: 300px;
}

.task>.row {
   column-gap: 20px;
}

.Dropdown.status {
   width: 115px;
}

.Input.customer {
   width: 150px;
}


.Input.estimatedMinutes,
.Input.totalMinutes {
   width: 85px;
}

.action {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
}

.settings {
   display: flex;
   align-items: center;
   padding: 10px;
   cursor: pointer;
   font-size: 20px;
}

.settings:hover {
   background-color: var(--activeHover)
}

.undo {
   display: flex;
   column-gap: 5px;
}
</style>