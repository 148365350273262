import { clone } from "@SyoLab/utils"

export function traverseMapToRecords(mapPointer, index, records) {
   if (Array.isArray(mapPointer)) {
      records.push({ index, timeEntrys: mapPointer })
   }
   else {
      for (let key in mapPointer) {
         traverseMapToRecords(mapPointer[key], `${index}.${key}`, records)
      }
   }
}

export function serviceRecordTemplate(settings) {
   let record = { value: 0, text: null }
   if (settings.showDate) record.date = ''
   if (settings.showEmployee) {
      record.employee_id = null
      record.employee = null
   }
   if (settings.showHourlyRate) record.hourlyRate = null
   if (settings.showFromTo) {
      record.from = null
      record.to = null
   }
   record.minutes = 0
   return {
      fieldNames: Object.keys(record),
      serviceRecord: record
   }
}


export function onTaskUpdate(service, config, reason) {

   if (reason == 'timeEntry') {
      service.status = 'wip'
   }

   // determine index of service record
   let indexFieldNames = serviceIndexFieldNames(service.settings)

   let serviceRecords = []
   if (indexFieldNames.length > 0) {
      // generate records grouped by indexFieldNames
      let map = mapToIndexFielNames(service, indexFieldNames)
      let records = unMapToRecords(map)
      serviceRecords = getServiceRecords(records, service.settings)
   } else {
      // summ into one timeEntry
      let timeEntrys = serviceRecordTimeEntrys(service)
      if (timeEntrys.length) {
         serviceRecords = [{
            _id: 'single',
            ...getServiceRecord(timeEntrys, service.settings)
         }]
      }

   }

   if (service.settings.showNonBillable) {
      let timeEntrys = serviceRecordTimeEntrys(service, true)
      if (timeEntrys.length) {
         let serviceRecord = {
            ...getServiceRecord(timeEntrys, service.settings),
            notBillable: true,
            text: 'Nicht verrechnete Arbeitszeit',
            _id: 'nonBillable',
         }
         serviceRecords.push(serviceRecord)
      }
   }

   let currentServiceIds = service.services.map((record) => record._id)
   serviceRecords.forEach((serviceRecord) => {
      let idx = service.services.findIndex((service) => service._id === serviceRecord._id)
      if (idx === -1) {
         service.services.push(serviceRecord)
      } else {
         service.services[idx] = serviceRecord
         // remove serviceId from serviceIds
         currentServiceIds = currentServiceIds.filter((id) => id !== serviceRecord._id)
      }
   })
   // clean up stale serviceRecords
   service.services = service.services.filter((service) => !currentServiceIds.includes(service._id))

   // sort by date
   service.services.sort((a, b) => {
      if (a.date < b.date) return -1
      if (a.date > b.date) return 1
      return 0
   })

   // update service totals
   service.totalMinutes = service.services.reduce((total, service) => {
      if (service.error) return total
      if (service.notBillable) return total
      return total + service.minutes
   }, 0)

   service.value = service.services.reduce((total, service) => {
      if (service.error) return total
      if (service.notBillable) return total
      let value = service.value || 0
      return total + value
   }, 0)
}


export function getTaskDataObject(data = {}, settings2 = {}) {

   data = clone(data)
   data.settings = { ...data.settings, ...settings2, }

   return {
      _id: null,
      type: 'task',
      name: null,
      project_id: null,
      projectName: null,
      customer_id: null,
      customerName: null,
      notBillable: false,
      serviceType: null,
      color: null,
      accountNo: null,
      businessDomain: null,
      // estimated
      estimatedMinutes: null,
      // actual totals
      totalMinutes: 0,
      totalMinutesNonBillable: 0,
      value: 0,
      valueNonBillable: 0,
      // status
      status: null,
      assignedTo: [],
      // serviceData
      services: [],
      timeEntrys: [],
      config: {},
      settings: {},
      createdBy: null,
      createdTimeStamp: null,
      userId: null,
      timeStamp: null,
      order: null, // sorting order
      // billing
      billingText: null,
      billNo: null,
      billingTimestamp: null,
      billedBy: null,
      ...data
   }
}

function serviceRecordTimeEntrys(service, notBillable) {
   return service.timeEntrys.filter(item => {
      // filter for valid timeEntrys only
      if (item.error) return false
      return notBillable ? item.notBillable : !item.notBillable
   })
}

function serviceIndexFieldNames(settings) {

   // text and fromTo -> all timeEntrys will be copied 1:1
   if (settings.showText || settings.showFromTo) {
      return ['_id']
   }

   let service_id = ''
   if (settings.showDate) service_id += '.date'
   if (settings.showEmployee) service_id += '.employee_id'
   if (settings.showHourlyRate) service_id += '.hourlyRate'

   return service_id.split('.').filter(item => item)
}


/**
 * Map timeEntrys to object with indexFieldNames
 * @param {array} indexFieldNames 
 * @returns object
 */
function mapToIndexFielNames(service, indexFieldNames) {
   let map = indexFieldNames.length ? {} : []

   serviceRecordTimeEntrys(service)
      .forEach((timeEntry) => {
         let mapPointer = map
         // move pointer up to the last level
         indexFieldNames.forEach((fieldName, index) => {
            let indexValue = timeEntry[fieldName]
            if (index === indexFieldNames.length - 1) {
               if (!mapPointer[indexValue]) mapPointer[indexValue] = []
            } else {
               if (!mapPointer[indexValue]) mapPointer[indexValue] = {}
            }
            mapPointer = mapPointer[indexValue]
         })
         mapPointer.push(timeEntry)
      })

   return map
}

function unMapToRecords(map) {
   let records = []
   Object.keys(map).forEach((key) => {
      if (Array.isArray(map[key])) {
         records.push({ index: key, timeEntrys: map[key] })
      } else {
         traverseMapToRecords(map[key], key, records)
      }
   })
   return records
}

function getServiceRecords(records, settings) {
   return records.map((record) => {
      let serviceRecord = getServiceRecord(record.timeEntrys, settings)
      return {
         _id: record.index,
         ...serviceRecord
      }
   })
}

function getServiceRecord(timeEntrys, settings) {

   let { fieldNames, serviceRecord } = serviceRecordTemplate(settings)

   timeEntrys.forEach((timeEntry) => {

      fieldNames.forEach((fieldName) => {
         if (fieldName == 'duration') return
         if (fieldName == 'hourlyRate') {
            if (!serviceRecord[fieldName]) {
               // initial value assignment
               serviceRecord[fieldName] = timeEntry[fieldName]
            } else if (serviceRecord[fieldName] != timeEntry[fieldName]) {
               // multiple hourlyRates 
               serviceRecord[fieldName] = 'n/a'
            }

         } else if (typeof serviceRecord[fieldName] == 'number') {
            // number => sum up 
            serviceRecord[fieldName] += timeEntry[fieldName]

         } else {
            // string => assign from timeEntry
            serviceRecord[fieldName] = timeEntry[fieldName]
         }
      })
   })
   if (!settings.showText) {
      // set default text
      serviceRecord.text = 'Arbeitszeit'
   }

   // do rounding
   if (serviceRecord.minutes && serviceRecord.hourlyRate) {
      if (!serviceRecord.from && !serviceRecord.to) {
         serviceRecord.minutes = Math.ceil(serviceRecord.minutes / 15) * 15;
         serviceRecord.value = serviceRecord.hourlyRate / 60 * serviceRecord.minutes;
      }
   }

   return serviceRecord
}
