export default [
   {
      serviceType: 'webProjects',
      key: 'aProjects',
      value: 'Agentur Projekte',
      businessDomain: 'web',
      accountNo: '3410',
      defaultWorkType: 'webDeveloper',
      color: '#589cf9',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'webSupport',
      key: 'aSupport',
      value: 'Agentur Support und Betrieb',
      businessDomain: 'web',
      accountNo: '3411',
      defaultWorkType: 'webSupport',
      color: '#0c57bd',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'webHosting',
      key: 'aHosting',
      value: 'Agentur Hosting & Lizenzen',
      businessDomain: 'web',
      accountNo: '3412',
      color: '#a4c9fd',
   },
   {
      serviceType: 'webAppProjects',
      key: 'wProjects',
      value: 'Webapplikationen Projekte',
      businessDomain: 'webApp',
      accountNo: '3430',
      defaultWorkType: 'applicationDeveloper',
      color: '#c858f9',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]

   },
   {
      serviceType: 'webAppSupport',
      key: 'wSupport',
      value: 'Webapplikationen Support und Betrieb',
      businessDomain: 'webApp',
      accountNo: '3431',
      defaultWorkType: 'applicationSupport',
      color: '#7f0cbd',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'webAppHosting',
      key: 'wHosting',
      value: 'Webapplikationen Hosting & Lizenzen',
      businessDomain: 'webApp',
      accountNo: '3432',
   },
   {
      serviceType: 'onlineMarketing',
      key: 'oMarketing',
      value: 'Online Marketing',
      businessDomain: 'onlineMarketing',
      accountNo: '3450',
      defaultWorkType: 'webMarketing',
      color: '#C63927',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'bchSubscriptions',
      key: 'bAbonnemente',
      value: 'BCH Import Abonnemente und Leistungen',
      businessDomain: 'bch',
      accountNo: '3420',
      color: '#DCBD49',
      emit: 'bchSubscriptions',
   },
   {
      serviceType: 'bchProducts',
      key: 'bProdukte',
      value: 'BCH Produkte',
      businessDomain: 'bch',
      accountNo: '3420',
      color: '#DCBD49',
      services: [
         { type: 'bchProduct', name: 'Rectangle', bchProductId: 'rectangle' },
         { type: 'bchProduct', name: 'Sponsored content Premium', bchProductId: 'sponsoredContentPremium' },
         { type: 'bchProduct', name: 'Sponsored content Professional', bchProductId: 'sponsoredContentProfessional' },
         { type: 'bchProduct', name: 'Sponsored content Publireport', bchProductId: 'sponsoredContentPublireport' },
         { type: 'bchProduct', name: 'Eventimport Einmalig', bchProductId: 'eventImport' },
         { type: 'bchProduct', name: 'Eventimport Einrichtung für aut. Import', bchProductId: 'eventImportSetup' },
         { type: 'bchProduct', name: 'Newsletter Werbung', bchProductId: 'newsletterWerbung' },
         { type: 'bchProduct', name: 'Manuelle Verrechnung', bchProductId: 'fixedPriceTask' },
         { type: 'project', name: 'Projekt' },
      ]
   },
   {
      serviceType: 'bchPrint',
      key: 'bPrint',
      value: 'BCH Print / Dritte',
      businessDomain: 'bch',
      accountNo: '3422',
      defaultWorkType: 'webDeveloper',
      color: '#DCBD49',
      services: [
         { type: 'bchProduct', name: '20 Minuten - Agenda Zürich', bchProductId: '20minAgendaZurich' },
         { type: 'bchProduct', name: '20 Minuten - Agenda Bern', bchProductId: '20minAgendaBern' },
         { type: 'bchProduct', name: '20 Minuten - Agenda BS, LU, SG', bchProductId: '20minAgendaBSLUSG' },
         { type: 'bchProduct', name: '20 Minuten - Agenda Deutschschweiz', bchProductId: '20minAgendaDe' },
         { type: 'bchProduct', name: 'Tagi - Agenda Special "Bildung"', bchProductId: 'tagiAgenda' },
         { type: 'bchProduct', name: 'ALPHA - DER KADERMARKT', bchProductId: 'tagiAlpha' }, // template
         { type: 'bchProduct', name: 'AUDIENZZ - NATIVE ADS (CPC)', bchProductId: 'nzzAudienz' }, // template
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'digitalHrProjects',
      key: 'hProjekte',
      value: 'Digital HR Projekte',
      businessDomain: 'digitalHR',
      accountNo: '3440',
      defaultWorkType: 'digitalHrConsultant',
      color: '#29bd0c',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'digitalHRSupport',
      key: 'hSupport',
      value: 'Digital HR Support',
      businessDomain: 'digitalHR',
      accountNo: '3441',
      defaultWorkType: 'digitalHrConsultant',
      color: '#077b09',
      services: [
         { type: 'task', name: 'Task' },
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
   {
      serviceType: 'digitalHRSubscriptions',
      key: 'hHosting',
      value: 'Digital HR Hosting & Lizenzen',
      businessDomain: 'digitalHR',
      accountNo: '3442',
      defaultWorkType: 'digitalHrConsultant',
      color: '#077b09',
      services: [
         { type: 'fixedPriceTask', name: 'Festpreis-Task' },
         { type: 'service', name: 'Leistung' },
         { type: 'project', name: 'Projekt' }
      ]
   },
]