var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Subscriptions",attrs:{"id":"App"}},[_c('Tabs',{staticClass:"large",attrs:{"tabs":_vm.tabs,"activeTab":_vm.activeTab},on:{"nav":function($event){_vm.activeTab = $event}}}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('Dropdown',{staticClass:"subscriptions rounded",attrs:{"width":"400px","options":{
               listItems: _vm.subscriptions,
               listItemKey: 'subscriptionType',
               listItemValueKey: 'name',
               returnListItem: true,
               autocomplete: true
            }},model:{value:(_vm.activeSubscription),callback:function ($$v) {_vm.activeSubscription=$$v},expression:"activeSubscription"}})],1),_c('div',{staticClass:"subscriptionGrid"},[(_vm.activeSubscription?.subscriptionType == 'typo3')?_c('Typo3SubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'webflow')?_c('WebflowSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'hosting')?_c('HostingSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'hostingEmail')?_c('HostingEmailSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'domainForwarding')?_c('DomainForwardingSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'webflowForms')?_c('WebflowFormsSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'phpExtendedSupport')?_c('PhpExtendedSupportSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'genericSubscription')?_c('GenericSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'websiteSchulen')?_c('WebsiteSchulenSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e(),(_vm.activeSubscription?.subscriptionType == 'webApp')?_c('WebAppSubscriptionGrid',{attrs:{"instances":_vm.instances,"config":_vm.activeSubscription}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }