<template>
   <div class="ServiceItems">
      <div class="grid">
         <div class="action header"></div>
         <div class="description header">Bezeichnung</div>
         <div class="count header">Anzahl</div>
         <div class="price header">Preis</div>
         <div class="discount header">Rabatt %</div>
         <div class="total header">Total</div>
         <div v-for="record in service.services"
            :key="record._id"
            class="gridRow">
            <div class="action">
               <div v-if="!readonly"
                  class="btn delete"
                  @click="onItemDelete(record)">
                  <Icon icon="iconDelete" />
               </div>
            </div>
            <div class="description">
               <InputBasic :readonly="readonly"
                  v-model="record.description" />
            </div>
            <div class="count">
               <InputBasic class="center"
                  :readonly="readonly"
                  v-model="record.count"
                  @input="recordValueUpdate(record)" />
            </div>
            <div class="price">
               <InputBasic v-model="record.price"
                  :readonly="readonly"
                  @input="recordValueUpdate(record)" />
            </div>
            <div class="discount">
               <InputBasic class="center"
                  :readonly="readonly"
                  v-model="record.discount"
                  @input="recordValueUpdate(record)" />
            </div>
            <div class="total">{{ toValue(record.value) }}</div>
         </div>

         <div v-if="!readonly"
            class="gridRow">
            <div class="add ">
               <div class="btn add"
                  @click="onAddItem">
                  <Icon icon="iconAdd" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Icon from "@icons/Icon.vue"
import { Clear, Add, Blocked2Solid } from "@icons/appFabric/icons"
import InputBasic from "@components/Form/InputBasic.vue"
import utils from "@SyoLab/utils"
export default {
   name: 'ServiceItems',
   components: { Icon, InputBasic },
   props: {
      readonly: { required: false, default: false },
      config: { required: true, type: Object },
      service: { required: true, type: Object }
   },
   data() {
      return {}
   },
   static: {
      iconDelete: Clear,
      iconAdd: Add,
      iconBlocked: Blocked2Solid
   },
   methods: {
      onAddItem() {
         this.service.services.push({
            _id: utils.shortId(),
            description: `Eintrag ${this.service.services.length + 1}`,
            count: 1,
            price: null,
            discount: null,
         })
      },
      onItemDelete(record) {
         setTimeout(() => {
            let index = this.service.services.findIndex(s => s._id == record._id)
            if (index > -1) {
               this.service.services.splice(index, 1)
            }
         }, 1)

      },
      recordValueUpdate(record) {
         let value = record.count * record.price * (1 - record.discount / 100)
         record.value = value ? Math.round((value + Number.EPSILON) * 100) / 100 : null
         record._hasChanges = true
         this.$emit('update')
      },
      toValue(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
   },
   computed: {},
}
</script>

<style scoped>
.ServiceItems {
   display: inline-flex;
   flex-direction: column;
   padding: 15px 0;
}

.grid {
   display: grid;
   grid-template-columns: [action] max-content [description] 450px [count] max-content [price] 85px [discount] 65px [total] 85px
}

.gridRow {
   display: contents;
}

.grid .header {
   padding: 0 5px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   font-size: 12px;
   font-weight: 500;
   color: rgba(0, 0, 0, 0.7);
   height: 38px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   padding-top: 0 !important;
   padding-bottom: 0 !important;

}

.gridRow>* {
   padding: 0 5px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   min-height: 38px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.gridRow>* .Input {
   width: 100%;
}

.gridRow>.add {
   grid-column: 1/-1;
   border-bottom: none;
   font-size: 14px;
   padding-top: 7px;
}

.grid .action {
   grid-column: action;
}

.grid .description {
   grid-column: description;
}



.grid .count {
   grid-column: count;
}

.grid .price {
   grid-column: price;
}

.grid .discount {
   grid-column: discount;
}

.grid .total {
   grid-column: total;
   justify-content: flex-end;
}

.btn {
   border-radius: 3px;
   padding: 3px;
}

.btn:hover {
   background-color: var(--activeHover);
}

.btn.delete {
   font-size: 11px;
   padding: 4px;
}
</style>