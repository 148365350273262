<template>
   <div class="BillingLists">
      <BillingPreview v-if="showPreview"
         :fullScreen="true" />
      <template v-else>
         <Tabs class="large"
            :tabs="tabs"
            :activeTab="activeTab"
            @nav="activeTab = $event" />
         <BillingList v-if="activeTab?._id == 1"
            apiUrl="billing"
            :showCustomerId="showCustomerId" />
         <BillingDeletedList v-if="activeTab?._id == 3" />
         <PaymentPendingList v-if="activeTab?._id == 2"
            apiUrl="billing"
            :showCustomerId="showCustomerId" />
      </template>

   </div>
</template>

<script>
import Tabs from '@components/Navigation/Tabs.vue';
import BillingList from './BillingList.vue';
import BillingDeletedList from './BillingDeletedList.vue';
import BillingPreview from './BillingPreview.vue';
import PaymentPendingList from './PaymentPendingList.vue';
export default {
   name: 'BillingLists',
   components: { Tabs, BillingList, BillingDeletedList, BillingPreview, PaymentPendingList },
   props: {
      showCustomerId: { required: false, default: false }
   },
   data() {
      return {
         tabs: [
            { _id: 1, text: 'Rechnungsliste', },
            { _id: 2, text: 'Offene Rechnungen', },
            { _id: 3, text: 'Gelöscht', },
         ],
         activeTab: {},
      }
   },
   methods: {},
   computed: {
      showPreview() {
         return this.$route.path == '/billing/preview'
      }
   },
}
</script>

<style scoped>
.BillingLists {
   display: flex;
   flex-direction: column;
}

.BillingList {
   margin-top: 15px;
}

.BillingDeletedList {
   margin-top: 15px;
}
</style>