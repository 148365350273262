<template>
   <div id="App"
      class="Subscriptions">
      <Tabs class="large"
         :tabs="tabs"
         :activeTab="activeTab"
         @nav="activeTab = $event" />
      <div class="body">
         <div class="row">
            <Dropdown class="subscriptions rounded"
               width="400px"
               :options="{
                  listItems: subscriptions,
                  listItemKey: 'subscriptionType',
                  listItemValueKey: 'name',
                  returnListItem: true,
                  autocomplete: true
               }"
               v-model="activeSubscription">
            </Dropdown>
         </div>
         <div class="subscriptionGrid">
            <Typo3SubscriptionGrid v-if="activeSubscription?.subscriptionType == 'typo3'"
               :instances="instances"
               :config="activeSubscription" />
            <WebflowSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'webflow'"
               :instances="instances"
               :config="activeSubscription" />
            <HostingSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'hosting'"
               :instances="instances"
               :config="activeSubscription" />
            <HostingEmailSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'hostingEmail'"
               :instances="instances"
               :config="activeSubscription" />
            <DomainForwardingSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'domainForwarding'"
               :instances="instances"
               :config="activeSubscription" />
            <WebflowFormsSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'webflowForms'"
               :instances="instances"
               :config="activeSubscription" />
            <PhpExtendedSupportSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'phpExtendedSupport'"
               :instances="instances"
               :config="activeSubscription" />
            <GenericSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'genericSubscription'"
               :instances="instances"
               :config="activeSubscription" />
            <WebsiteSchulenSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'websiteSchulen'"
               :instances="instances"
               :config="activeSubscription" />
            <WebAppSubscriptionGrid v-if="activeSubscription?.subscriptionType == 'webApp'"
               :instances="instances"
               :config="activeSubscription" />
         </div>
      </div>
   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue'
import Tabs from '@components/Navigation/Tabs.vue';
import Icon from "@icons/Icon.vue"
import { Search, } from "@icons/appFabric/icons"
import api from '@components/Portal/api'

import Typo3SubscriptionGrid from './typo3/Typo3SubscriptionGrid.vue'
import WebflowSubscriptionGrid from './webflow/WebflowSubscriptionGrid.vue';
import HostingSubscriptionGrid from './hosting/HostingSubscriptionGrid.vue';
import HostingEmailSubscriptionGrid from './hostingEmail/HostingEmailSubscriptionGrid.vue';
import DomainForwardingSubscriptionGrid from './domainForwarding/DomainForwardingSubscriptionGrid.vue';
import WebflowFormsSubscriptionGrid from './webflowForms/WebflowFormsSubscriptionGrid.vue';
import PhpExtendedSupportSubscriptionGrid from './phpExtendedSupport/PhpExtendedSupportSubscriptionGrid.vue';
import GenericSubscriptionGrid from './genericSubscription/GenericSubscriptionGrid.vue';
import WebsiteSchulenSubscriptionGrid from './websiteSchulen/WebsiteSchulenSubscriptionGrid.vue';
import WebAppSubscriptionGrid from './webAppSubscription/WebAppSubscriptionGrid.vue';

export default {
   name: 'Subscriptions',
   components: { Tabs, Dropdown, Typo3SubscriptionGrid, Icon, WebflowSubscriptionGrid, HostingSubscriptionGrid, HostingEmailSubscriptionGrid, DomainForwardingSubscriptionGrid, WebflowFormsSubscriptionGrid, PhpExtendedSupportSubscriptionGrid, GenericSubscriptionGrid, WebsiteSchulenSubscriptionGrid, WebAppSubscriptionGrid },
   async setup(props, context) { },
   props: {},
   data() {
      return {
         tabs: [
            { _id: 1, text: 'Abos', },
            // { _id: 2, text: 'Mahnliste', },
            // { _id: 3, text: 'Gelöscht', },
         ],
         activeTab: {},
         subscriptions: [],
         activeSubscription: null,
         instances: []
      }
   },
   static: {
      iconSearch: Search,
   },
   methods: {
      async getSubscriptions() {
         this.instances = await api.get('subscriptions/instances')
         this.subscriptions = await api.get('subscriptions/list')
      },
   },
   computed: {
   },
   created() {
      this.getSubscriptions()
   }
}
</script>

<style scoped>
.Subscriptions {}

.body {
   padding-top: 25px;
}
</style>