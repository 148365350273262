var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Customer",attrs:{"id":"App"}},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"customer"},[_c('div',{staticClass:"searchRow"},[_c('Dropdown',{ref:"customerSearch",staticClass:"rounded",attrs:{"placeholder":"Kunde suchen","options":{
                  autocomplete: true,
                  listItemKey: '_id',
                  listItemValueKey: 'nameCity',
                  returnListItem: false,
                  autocompleteKeys: true,
                  triangle: false
               },"autocomplete":_vm.onCustomerSearch},on:{"input":_vm.onCompanySelect,"autocomplete":function($event){_vm.searchString = $event}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('Icon',{attrs:{"icon":_vm.$static.iconSearch}})]},proxy:true}])}),_c('div',{staticClass:"addCustomerPlaceHolder"},[(_vm.userApp.showBilling && !_vm.isSearching && _vm.searchString && _vm.searchResult.length == 0)?_c('div',{staticClass:"addCustomerBtn",on:{"click":_vm.onAddCustomerDialog}},[_c('Icon',{attrs:{"icon":"iconAdd"}})],1):_vm._e()])],1),(_vm.activeCustomer)?[_c('CustomerData',{attrs:{"customer":_vm.activeCustomer},on:{"edit":function($event){return _vm.onCustomerEdit(_vm.activeCustomer)}}}),_c('Contacts',{attrs:{"customer":_vm.activeCustomer}}),_c('Communication',{attrs:{"customer":_vm.activeCustomer}})]:_vm._e(),(_vm.editCustomerDialog)?_c('CustomerDataDialog',{attrs:{"editCustomer":_vm.editCustomer,"transferTo":"modal","top":"50px","left":"100px"},on:{"save":_vm.editCustomerSave,"close":_vm.editCustomerCancel}}):_vm._e(),_c('portal-target',{attrs:{"name":"customerDialog"}})],2),_c('div',{staticClass:"apps"},[_c('Tabs',{staticClass:"large",attrs:{"tabs":_vm.tabs,"activeTab":_vm.activeTab},on:{"nav":function($event){_vm.activeTab = $event}}}),_c('div',{staticClass:"row"},[(_vm.activeCustomer)?[(_vm.activeTab?._id == 1)?_c('CustomerServices',{attrs:{"apiUrl":"customer/","activeCustomer":_vm.activeCustomer,"showBilling":_vm.userApp.showBilling,"admin":_vm.userApp.admin}}):_vm._e(),(_vm.activeTab?._id == 2)?_c('BillingList',{attrs:{"apiUrl":"billing","customer_id":_vm.activeCustomer?._id || null}}):_vm._e(),(_vm.activeTab?._id == 3)?_c('CustomerSubscriptions',{attrs:{"apiUrl":"customer","customer_id":_vm.activeCustomer?._id || null,"customerName":_vm.activeCustomer?.name || null}}):_vm._e()]:_vm._e()],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }