<template>
   <div class="CustomerServices">
      <!-- <div class="products">
         <Button class="blue"
            @click="getProducts">Publibox</Button>
      </div> -->
      <Services ref="Services"
         :apiUrl="apiUrl"
         :showBilling="showBilling"
         :customer_id="activeCustomer?._id || null"
         :customerName="activeCustomer?.name || null"
         :notBillable="activeCustomer?.notBillable || null"
         :admin="admin"
         @bchSubscriptions="getProducts" />

      <Dialog v-if="showBchOrderList"
         :loader="showBchOrderInfoLoader"
         :maxHeight="($portal.portalHeight - 50) + 'px'"
         scroll="true"
         transferTo="modal"
         closeBtn="true"
         @close="onProductInfoClose"
         @outsideClick="onProductInfoClose">
         <div class="dlgBody">
            <div class="orders">
               <BchOrder v-for="order in bchOrderList"
                  :uploadBtn="true"
                  :showCollapsed="true"
                  :order="order"
                  :key="order.id"
                  @upload="onUpload(order)" />
            </div>
         </div>
      </Dialog>
   </div>
</template>

<script>
import Services from '../../components/services/Services.vue'
import Button from '@components/Form/Button.vue';
import api from '@components/Portal/api';
import Dialog from '@components/Dialog/Dialog.vue';
import BchOrder from '../../components/services/bch/bchOrder/BchOrder.vue';
import eventBus from '@components/Portal/eventBus'
export default {
   name: 'CustomerServices',
   components: { Services, Button, Dialog, BchOrder },
   props: {
      apiUrl: { required: true },
      activeCustomer: { required: true },
      showBilling: { required: false, default: true },
      admin: { required: false, default: false }
   },
   data() {
      return {
         showBchOrderList: false,
         showBchOrderInfoLoader: true,
         bchOrderList: [],
      }
   },
   methods: {
      async getProducts() {
         if (!this.activeCustomer.bchId) return
         this.showBchOrderList = true
         this.showBchOrderInfoLoader = true
         this.bchOrderList = await api.get(`customer/bch/orderList/${this.activeCustomer.bchId}`)
         this.showBchOrderInfoLoader = false
      },
      onProductInfoClose() {
         this.showBchOrderList = false

      },
      async onUpload(order) {
         let res = await api.get(`customer/bch/orderUpsert/${order.id}`)
         if (!res || res.success === false) {
            eventBus.emit('notify.warning', res.message)
            return
         }
         eventBus.emit('notify.info', res.value.name + ' importiert')
         this.$refs.Services.getData()
      },

   },
   computed: {

   },
}
</script>

<style scoped>
.CustomerServices {
   display: flex;
   flex-direction: column;
}

.products {
   display: flex;
   margin-bottom: 20px;
}

.dlgBody {
   display: flex;
   padding-top: 50px;
   width: 600px;
}

.orders {
   display: flex;
   flex-direction: column;
   gap: 15px;
   width: 100%;
}
</style>