<template>
   <div id="App"
      class="Customer">
      <div class="grid">
         <div class="customer">
            <div class="searchRow">
               <Dropdown class="rounded"
                  ref="customerSearch"
                  placeholder="Kunde suchen"
                  :options="{
                     autocomplete: true,
                     listItemKey: '_id',
                     listItemValueKey: 'nameCity',
                     returnListItem: false,
                     autocompleteKeys: true,
                     triangle: false
                  }"
                  :autocomplete="onCustomerSearch"
                  @input="onCompanySelect"
                  @autocomplete="searchString = $event">
                  <template #before>
                     <Icon :icon="$static.iconSearch" />
                  </template>
               </Dropdown>
               <div class="addCustomerPlaceHolder">
                  <div v-if="userApp.showBilling && !isSearching && searchString && searchResult.length == 0"
                     class="addCustomerBtn"
                     @click="onAddCustomerDialog">
                     <Icon icon="iconAdd" />
                  </div>
               </div>
            </div>

            <template v-if="activeCustomer">
               <CustomerData :customer="activeCustomer"
                  @edit="onCustomerEdit(activeCustomer)" />
               <Contacts :customer="activeCustomer" />
               <Communication :customer="activeCustomer" />
            </template>
            <CustomerDataDialog v-if="editCustomerDialog"
               :editCustomer="editCustomer"
               transferTo="modal"
               top="50px"
               left="100px"
               @save="editCustomerSave"
               @close="editCustomerCancel" />
            <portal-target name="customerDialog" />
         </div>
         <div class="apps">
            <Tabs class="large"
               :tabs="tabs"
               :activeTab="activeTab"
               @nav="activeTab = $event" />
            <div class="row">
               <template v-if="activeCustomer">
                  <CustomerServices v-if="activeTab?._id == 1"
                     apiUrl='customer/'
                     :activeCustomer="activeCustomer"
                     :showBilling="userApp.showBilling"
                     :admin="userApp.admin" />
                  <BillingList v-if="activeTab?._id == 2"
                     apiUrl='billing'
                     :customer_id="activeCustomer?._id || null" />
                  <CustomerSubscriptions v-if="activeTab?._id == 3"
                     apiUrl='customer'
                     :customer_id="activeCustomer?._id || null"
                     :customerName="activeCustomer?.name || null" />
               </template>

            </div>
         </div>
      </div>

   </div>
</template>

<script>
import Dropdown from '@components/Form/Dropdown.vue'
import api from "@components/Portal/api.js"
import Icon from "@icons/Icon.vue"
import { Search, Group, Add } from "@icons/appFabric/icons"

import { DateTime, Timestamp, Day } from '@SyoLab/date-time';
import { ObjectId } from '@SyoLab/utils';

import Tabs from '@components/Navigation/Tabs.vue';
import Contacts from './Contacts.vue'
import Communication from './Communication.vue'
import CustomerData from './CustomerData.vue'
import CustomerDataDialog from './CustomerDataDialog.vue'
import CustomerServices from './CustomerServices.vue'
import CustomerSubscriptions from './CustomerSubscriptions.vue'

import BillingList from '../billing/BillingList.vue';

export default {
   name: 'Customer',
   components: { Dropdown, Contacts, Icon, Communication, CustomerData, CustomerDataDialog, Tabs, BillingList, CustomerServices, CustomerSubscriptions },
   props: {
      userApp: { required: true },
   },
   data() {
      return {
         activeCustomer_id: null,
         activeCustomer: null,
         // params to display addButton 
         searchString: null,
         searchResult: [],
         isSearching: false,
         // edit / add Customer
         editCustomerDialog: false,
         editCustomer: null,
         // tabs
         tabs: [
            { _id: 1, text: 'Leistungen', },
            { _id: 2, text: 'Rechnungen', show: this.userApp.showBilling },
            { _id: 3, text: 'Hosting & Abos', },
         ],
         activeTab: {},
      }
   },
   static: {
      iconSearch: Search,
      iconGroup: Group,
      iconAdd: Add,
   },
   methods: {
      async onCustomerSearch(searchString) {
         if (!searchString) {
            return []
         }
         this.isSearching = true
         let res = await api.get(`customer/customerSearch/${searchString}`)
         this.searchResult = res
         this.isSearching = false
         return res
      },
      async getCustomer(customer_id) {

         if (customer_id) this.activeCustomer_id = customer_id
         this.activeCustomer = await api.get(`customer/customer/${this.activeCustomer_id}`)
         if (this.activeCustomer) {
            sessionStorage.setItem("activeCustomer_id", this.activeCustomer_id)
         }


      },
      onCompanySelect(selectedItem) {
         this.searchString = null
         this.searchResult = []
         this.activeCustomer_id = selectedItem
         this.getCustomer()
      },
      onAddCustomerDialog() {
         this.onCustomerEdit({ name: this.$refs.customerSearch.autocompleteText })
      },
      onCustomerEdit(customer) {
         this.editCustomer = {
            _id: customer._id || null,
            id: customer.id || null,
            shortName: customer.shortName || null,
            firstName: customer.firstName || null,
            name: customer.name || null,
            name2: customer.name2 || null,
            street: customer.street || null,
            street2: customer.street2 || null,
            zip: customer.zip || null,
            city: customer.city || null,
            country: customer.country || null,
            homepage: customer.homepage || null,
            email: customer.email || null,
            phone: customer.phone || null,
         }

         this.editCustomerDialog = true
      },
      async editCustomerSave(data) {
         if (!data._id) {
            this.createCustomer(data)
            return
         }
         // get next id from customer collection
         if (!data.id) {
            let res = await api.get('customer/nextCustomerId')
            this.$set(data, 'id', String(res.id))
         }
         if (!data.shortName) data.shortName = data.name
         data.lastUpdate = Timestamp()
         await api.post('customer/customerData', data)

         // update active Customer
         Object.keys(data).forEach(key => {
            this.activeCustomer[key] = data[key]
         })
         this.editCustomerDialog = false
         this.editCustomer = null

      },
      async createCustomer(data) {
         delete data._id

         let newCustomer = {
            _id: ObjectId(),
            ...data,
            contacts: [],
            customerCommunication: [],
            lastUpdate: Timestamp(),
         }

         this.editCustomerSave(newCustomer)

         this.activeCustomer = newCustomer
         this.activeCustomer_id = newCustomer._id
         this.editCustomerDialog = false
         this.editCustomer = null
      },
      editCustomerCancel() {
         this.editCustomerDialog = false
         this.editCustomer = null
      },
   },
   computed: {},
   created() {

      if (this.$route.params.id) {
         this.getCustomer(this.$route.params.id)
         this.$router.push('/customer') // reset route param
      } else if (sessionStorage.getItem("activeCustomer_id")) {
         this.getCustomer(sessionStorage.getItem("activeCustomer_id"))
      }
   }
}
</script>

<style scoped>
.Customer {}

.Contacts {
   z-index: 4;
}

.Communication {
   width: 100%;
}

.searchRow {
   display: flex;
   margin-top: 20px;
   margin-bottom: 30px;
   width: 630px;
   column-gap: 10px;
}

.searchRow .Dropdown {
   flex-grow: 1;
}

.grid {
   display: grid;
   grid-template-columns: 650px 1fr;
   grid-template-rows: 1fr;
}

.grid>.customer {
   position: relative;
   display: flex;
   flex-direction: column;
   row-gap: 30px;
}

.addCustomerPlaceHolder {
   height: 36px;
   width: 36px;
}

.addCustomerBtn {
   height: 36px;
   width: 36px;
   border-radius: 50%;
   border: 1px solid rgb(217, 217, 217);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 13px;
   color: grey;
   cursor: pointer;
}

.addCustomerBtn:hover {
   background-color: var(--activeHover);
}

.apps {
   display: flex;
   flex-direction: column;
   row-gap: 35px;
   padding: 26px 20px 0 55px;
}
</style>