<template>
   <div class="BillingListStats">
      <div class="grid"
         :style="gridStyle">
         <div class="record">
            <div v-for="m in months"
               :key="m.month"
               class="header month">{{ m.text }}</div>
            <div class="details">Details</div>
         </div>
         <div v-for="(record, index) in salesVolumeMonthly"
            :key="index"
            class="record"
            :class="record.accountNo">
            <template v-for="m in months">
               <div v-if="m.month == 'accountNo'"
                  class="accountNo"
                  :style="{ gridColumn: `maccountNo`, gridRow: index + 2, minWidth: '190px' }"
                  :key="m.month">{{ accounts[record.accountNo]?.serviceName }}</div>
               <div v-else-if="m == 'total'"
                  class="total value"
                  :style="{ gridColumn: `mtotal`, gridRow: index + 2 }"
                  :key="m.month">{{ toFormatNumber(record.total) }}</div>
               <div v-else
                  class="month value"
                  :style="{ gridColumn: `m${m.month}`, gridRow: index + 2 }"
                  :key="m.month">{{ toFormatNumber(record[m.month]) }}</div>
            </template>
            <div class="details">
               <template v-if="record.accountNo == 'total'">
                  <div class="showDetails"
                     :class="{ open: showDetails }"
                     @click="showDetails = !showDetails">
                     <Icon icon="iconChevronDownSmall" />
                  </div>
               </template>
               <template v-else>
                  &nbsp;
               </template>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import serviceConfig from "../../components/services/serviceConfig"
import { DateTime, Day } from "@SyoLab/date-time";
import Icon from "@icons/Icon.vue"
import { ChevronDownSmall } from "@icons/appFabric/icons"
function isoGetYear(date) {
   if (!date || typeof date !== 'string') return null
   return date.split('-')[0]
}
export default {
   name: 'BillingListStats',
   components: { Icon },
   async setup(props, context) { },
   props: {
      bills: { required: true },
      startMonth: { required: false },
      endMonth: { required: false }
   },
   data() {
      return {
         showDetails: false,
      }
   },
   static: {
      iconChevronDownSmall: ChevronDownSmall,
   },
   methods: {
      toFormatNumber(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
         })
      },
   },
   computed: {
      salesVolumeMonthly() {
         let records = []
         let totalRecord = { accountNo: 'total', total: 0 }
         records.push(totalRecord)
         Object.keys(this.accounts).sort().forEach(accountNo => {
            if (accountNo == 'total') return
            let record = { accountNo: accountNo, total: 0 }
            this.months.forEach(month => {
               if (this.groupedByMonthAccount[month.month]?.[accountNo]) {
                  record[month.month] = this.groupedByMonthAccount[month.month][accountNo]
                  record.total += record[month.month]
                  totalRecord.total += record[month.month]
                  if (!totalRecord[month.month]) totalRecord[month.month] = 0
                  totalRecord[month.month] += record[month.month]
               }
            })

            records.push(record)
         })

         if (!this.showDetails) return [records[0]]
         return records
      },
      groupedByMonthAccount() {
         return this.bills.reduce((acc, bill) => {
            let month = bill.date.substr(0, 7)
            if (!acc[month]) acc[month] = {}
            bill.revenueAccounts.forEach(record => {
               if (!acc[month][record.accountNo]) acc[month][record.accountNo] = 0
               acc[month][record.accountNo] += Math.round(record.value)
            })

            return acc
         }, {})
      },
      accounts() {
         let accounts = new Set()
         this.months.forEach(record => {
            let month = record.month
            if (this.groupedByMonthAccount[month]) {
               Object.keys(this.groupedByMonthAccount[month]).forEach(accountNo => {
                  accounts.add(accountNo)
               })
            }

         })
         accounts = Array.from(accounts)
         accounts.sort()
         let ret = accounts.map(accountNo => {
            let service = serviceConfig.find(s => s.accountNo === accountNo)
            if (service) {
               return {
                  accountNo,
                  serviceType: service.serviceType,
                  serviceName: service.value,
                  color: service.color
               }
            }
            return {
               accountNo,
            }
         })
         ret.push({ accountNo: 'total', serviceName: 'Total' })
         return Object.fromEntries(ret.map(a => [a.accountNo, a]))
      },
      months() {
         let y = new Day().year
         let groupedMonths = Object.keys(this.groupedByMonthAccount).sort()

         let startMonth = this.startMonth || groupedMonths[0]
         let endMonth = this.endMonth || groupedMonths[groupedMonths.length - 1]

         let months = []
         let d = new Day(startMonth)
         while (d.month <= endMonth) {
            months.push(d.month)
            d = d.plus({ months: 1 })
         }

         months = months.map(month => {
            let text;
            if (isoGetYear(month) != y) {
               text = new Day(month).toDateTime().toFormat('LLL yy', { locale: 'de-ch' })
            } else {
               text = new Day(month).toDateTime().toFormat('LLL', { locale: 'de-ch' })
            }
            return {
               month,
               text
            }
         })

         return [{
            month: 'accountNo',
            text: ''
         }].concat(months, {
            month: 'total',
            text: 'Total'
         })
      },
      headerMonths() {
         if (this.showDetails) {
            return [this.months[0]]
         }
         return this.months
      },
      gridStyle() {
         let template = this.months.map(account => `[m${account.month}] max-content`).join(' ')
         return {
            gridTemplateColumns: template + ' [total] max-content' + ' [details] max-content',
         }
      }
   },
}
</script>

<style scoped>
.BillingListStats {}

.grid {
   display: grid;

   .record {
      display: contents;

      .header {
         padding: 5px 10px 13px 10px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
         display: flex;
         cursor: pointer;
         font-size: 12px;
         font-weight: 500;
         color: rgba(0, 0, 0, 0.7);
      }

      .accountNo {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }

      .total,
      &.total {
         font-weight: 500;
      }

      .value {
         text-align: right;
      }

      >div {
         padding: 7px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
         display: flex;
         align-items: center;
      }

      .details {
         grid-column: details;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 14px;

         .showDetails {
            cursor: pointer;
            padding: 5px;

            &.open {
               transition-duration: 0.5s;
               transform: rotate(180deg);
            }
         }
      }
   }
}
</style>