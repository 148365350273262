<template>
	<div class="FileUpload"
		:class="{ disabled: disabled }"
		@click="onClick">
		<form ref="form"
			enctype="multipart/form-data">
			<input ref="input"
				type="file"
				name="file"
				class="inputfile"
				@change="onInputChange"
				:accept="accept"
				:disabled="disabled"
				:multiple="multiple"
				@formdata="onFileSelect" />
			<label>
				<slot>Datei auswählen</slot>
			</label>
		</form>
	</div>
</template>

<script>
import api from '@components/Portal/api'
export default {
	name: 'FileUpload',
	components: {},
	props: {
		accept: { required: false, default: null },
		multiple: { required: false, default: false },
		defaultText: { required: false },
		url: { required: false },
		sse: { required: false },
		metadata: { required: false, default: () => { } },
		disabled: { required: false },
		pdfToImage: { required: false, default: false },
	},
	data() {
		return {
			selectedFiles: [],
			selectedFileName: undefined,
		}
	},
	methods: {
		onClick() {
			this.$refs.input.click()
			this.$emit('click')
		},
		onInputChange(event) {
			this.selectedFiles = event.srcElement.files
			let files = []
			Array.from(event.srcElement.files).forEach(file => {
				files.push({
					fileName: file.name,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
					size: file.size,
					type: file.type,
					file: file,
				})
			})

			if (this.multiple) {
				this.$emit('select', files)
			} else {
				this.$emit('select', files[0])
			}

			// triggers formdata - event
			new FormData(this.$el.querySelector('form'))
		},
		async onFileSelect(e) {
			let formData = e.formData

			for (const key in this.metadata) {
				// append metadata to form data
				if (Object.hasOwnProperty.call(this.metadata, key)) {
					formData.append(key, this.metadata[key])
				}
			}

			try {
				if (this.url) {
					await this.upload(formData)
				} else {
					this.$emit('formdata', formData)
				}
				this.$el.querySelector('form').reset()
			} catch (error) {
				this.$el.querySelector('form').reset()
				this.$emit('error', error)
			}
		},
		async upload(formData) {
			if (this.sse) {
				let sse = api.sse()
				sse.on('data', data => {
					this.$emit('data', data)
				})
				sse.on('error', data => {
					this.$emit('error', data)
				})
				sse.on('connect', data => {
					this.$emit('connect', data)
				})
				sse.on('disconnect', data => {
					this.$emit('success', data)
				})

				await sse.post(this.url, formData)
			} else {
				let res = await api.post(this.url, formData)
				this.$emit('success', res)
			}
		},
		open() {
			this.$refs.input.click()
		},
	},
	computed: {},
	mounted() {
		this.$el.querySelector('form').addEventListener('formdata', this.onFileSelect)
	},
	beforeDestroy() {
		this.$el.querySelector('form').removeEventListener('formdata', this.onFileSelect)
	},
}
</script>

<style scoped>
.FileUpload {}

.FileUpload.disabled {
	cursor: default;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

form,
label {
	height: 100%;
	width: 100%;
	cursor: inherit;
	font-size: inherit;
	color: inherit;
}

label {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	padding: 0;
}
</style>
