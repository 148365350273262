<template>
   <div id="App"
      class="Billing">
      <BillingLists :showCustomerId="true" />
   </div>
</template>

<script>
import BillingLists from './BillingLists.vue';
export default {
   name: 'Billing',
   components: {
      BillingLists
   },
   props: {},
   data() {
      return {}
   },
   methods: {},
   computed: {},
}
</script>

<style scoped>
.Billing {}
</style>