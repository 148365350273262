import { render, staticRenderFns } from "./DomainForwardingSubscriptionGrid.vue?vue&type=template&id=417788b8&scoped=true"
import script from "./DomainForwardingSubscriptionGrid.vue?vue&type=script&lang=js"
export * from "./DomainForwardingSubscriptionGrid.vue?vue&type=script&lang=js"
import style0 from "./DomainForwardingSubscriptionGrid.vue?vue&type=style&index=0&id=417788b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417788b8",
  null
  
)

export default component.exports