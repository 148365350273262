<template>
   <div class="PaymentPendingList">
      <div class="actionRow">
         <CheckBox v-model="showDueOnly">Nur fällige anzeigen {{ billsTotal }}</CheckBox>
      </div>
      <BillingGrid :bills="billsFiltered"
         :showCustomerId="showCustomerId"
         pdfUrl="/api/billing/bill/pdf/"
         @deleteBill="showDeleteDialog = $event" />
   </div>
</template>

<script>
import BillingGrid from './grid/BillingGrid.vue';
import api from '@components/Portal/api';
import { DateTime, Day } from "@SyoLab/date-time";
import CheckBox from '@components/Form/CheckBox.vue';
export default {
   name: 'PaymentPendingList',
   components: { BillingGrid, CheckBox },
   async setup(props, context) { },
   props: {
      customer_id: { required: false },
      apiUrl: { required: false },
      showCustomerId: { required: false, default: false }
   },
   data() {
      return {
         bills: [],
         showDueOnly: true
      }
   },
   methods: {
      async getData() {
         this.bills = await api.post(`${this.apiUrl}/list/paymentPending`, {
            // dueDate: {
            //    $lt: new Day().date
            // }
         })
      },
      toFormatNumber(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
         })
      },
   },
   computed: {
      billsFiltered() {
         let date = new Day().date
         if (this.showDueOnly) {
            return this.bills.filter(bill => bill.dueDate < date)
         }
         return this.bills
      },
      billsTotal() {
         let total = this.billsFiltered.reduce((acc, bill) => {
            return acc + bill.value
         }, 0)
         if (!total) return null
         return `(${this.toFormatNumber(total)})`
      }
   },
   created() {
      this.getData()
   }
}
</script>

<style scoped>
.PaymentPendingList {
   padding-top: 25px;
}

.actionRow {
   padding-bottom: 25px;
}
</style>