<template>
   <div class="WebflowCreateSubscription">
      <div class="row">
         <Dropdown v-if="!customer_id"
            :options="{
               autocomplete: true,
               listItemKey: '_id',
               listItemValueKey: 'nameCity',
               returnListItem: false,
               autocompleteKeys: true,
            }"
            label="Kunde"
            width="410px"
            :autocomplete="onCustomerSearch"
            @input="onCompanySelect"
            @autocomplete="searchString = $event" />
      </div>
      <Input label="Domain"
         width="410px"
         :options="{
            before: 'https://'
         }"
         v-model="subscription.domain" />
      <div class="row gap">
         <Input label="Monat"
            width="85px"
            :options="{
               after: 'CHF'
            }"
            :value="subscription.monthly"
            @input="onCreateSubscriptionMonthly" />
         <Input label="Jahr"
            width="85px"
            :options="{
               after: 'CHF'
            }"
            :value="subscription.yearly"
            @input="onCreateSubscriptionYearly" />
         <DatePicker label="Seit"
            class="noNotification"
            format="toIsoDate"
            v-model="subscription.since" />
      </div>
      <div class="row gap">
         <DatePicker label="Beginn"
            class="noNotification"
            v-model="subscription.startDate"
            @input="onCreateSubscriptionStartDate"
            format="toIsoDate" />
         <DatePicker label="Ende"
            class="noNotification"
            format="toIsoDate"
            v-model="subscription.endDate" />
      </div>
      <Textarea placeholder="Kommentar"
         v-model="subscription.comment" />
      <div class="row action">
         <Button class="blue"
            :enabled="!disabled"
            @click="$emit('save')">Speichern</Button>
      </div>

   </div>
</template>

<script>
import Input from '@components/Form/Input.vue';
import DatePicker from '@components/Form/DatePicker.vue';
import Dropdown from '@components/Form/Dropdown.vue';
import Button from '@components/Form/Button.vue'
import Textarea from '@components/Form/Textarea.vue';
import { Day, Timestamp, } from '@SyoLab/date-time'
import api from '@components/Portal/api'

export default {
   name: 'WebflowCreateSubscription',
   components: { Input, DatePicker, Dropdown, Button, Textarea },
   async setup(props, context) { },
   props: {
      subscription: { required: true },
      customer_id: { required: false },
      customerName: { required: false },
   },
   data() {
      return {
         searchString: null,
      }
   },
   methods: {
      onCreateSubscriptionYearly(value) {
         if (isNaN(value)) {
            value = null
         }
         value = Number(value)
         this.subscription.yearly = value
      },
      onCreateSubscriptionMonthly(value) {
         if (isNaN(value)) {
            value = null
         }
         value = Number(value)
         this.subscription.monthly = value
      },
      onCreateSubscriptionStartDate(date) {
         if (!date) return
         this.subscription.endDate = new Day(date).plus({ year: 1 }).minus({ day: 1 }).date
      },
      async onCustomerSearch(searchString) {
         if (!searchString) {
            return []
         }

         let res = await api.get(`subscriptions/customerSearch/${searchString}`)
         return res
      },
      async getCustomer(customer_id) {
         if (!customer_id) return
         let customer = await api.get(`subscriptions/customer/${customer_id}`)

         if (this.subscription) {
            this.subscription.customer_id = customer._id
            this.subscription.customerName = customer.name
         }

      },
      onCompanySelect(selectedItem) {
         this.searchString = null
         this.getCustomer(selectedItem)
      },
   },
   computed: {
      disabled() {
         if (!this.subscription.name) return true
         if (!this.subscription.customer_id) return true
         if (!this.subscription.monthly && !this.subscription.yearly) return true
         if (!this.subscription.startDate) return true
         if (!this.subscription.endDate) return true
         return false
      }
   },
}
</script>

<style scoped>
.WebflowCreateSubscription {}

.Textarea {
   background-color: var(--activeHover);
   min-height: 75px;
   width: 100%;
   margin-bottom: 25px;
   padding: 5px;
}
</style>