<template>
   <div class="Services"
      :style="style">
      <div v-if="showAddService || showCustomerProjectsSwitcher || showServiceBillingBtn"
         class="action">
         <div class="left">
            <ServicesAddDropdown v-if="showAddService"
               ref="ServiceAddDropdown"
               :config="config"
               @emit="onEmit"
               @addService="onAddService" />
         </div>
         <div class="middle">
            <Switcher v-if="showTaskTotalSwitcher"
               class="material"
               :value="serviceGridTaskTotalCHF"
               @input="onServiceGridShowTaskTotal">% CHF</Switcher>
            <Switcher v-if="showBilledSwitcher"
               class="material billedFilter"
               :value="billedSwitcherValue"
               @input="onBilledSwitcherInput">
               <Icon class="iconBill"
                  :icon="$static.iconBill" />
            </Switcher>
         </div>
         <div class="right">
            <ServicesBillingBtn :showBillingBtn="showServiceBillingBtn"
               :services="services"
               :config="config"
               :selectedCustomer_id="selectedCustomer_id"
               :selectedServices="selectedServices"
               @selectable="selectable = $event"
               @select="onSelect" />
         </div>
      </div>
      <ServicesFilters :config="config"
         :services="services"
         :filters="serviceGridFilters" />
      <ServicesGrid ref="ServiceGrid"
         :config="config"
         :services="services"
         :filters="serviceGridFilters"
         :selectable="selectable"
         :taskTotalCHF="serviceGridTaskTotalCHF"
         :showCustomer="serviceGridShowCustomer"
         :showProjects="serviceGridShowProjects"
         :selectedCustomer_id="selectedCustomer_id"
         :selectedServices="selectedServices"
         :toggleItems="toggleItems"
         @projectAddService="onAddService"
         @projectAddServiceType="onAddServiceType"
         @bchReload="onBchReload"
         @edit="onEdit"
         @select="onSelect"
         @update="onUpdate"
         @delete="onDelete" />
      <ServicesEditDialog ref="ServiceEditDialog"
         :config="config"
         :services="services"
         :selectable="selectable"
         @edit="onEdit"
         @select="onSelect"
         @update="onUpdate"
         @delete="onDelete"
         @bchReload="$emit('bchReload', $event)" />
      <ServicesAddDialog ref="ServiceAddDialog"
         :config="config"
         :services="services"
         @edit="onEdit" />
   </div>
</template>

<script>

import ServicesAddDropdown from './ServicesAddDropdown.vue';
import ServicesGrid from './ServicesGrid.vue';
import ServicesEditDialog from './ServicesEditDialog.vue';
import ServicesAddDialog from './ServicesAddDialog.vue';
import ServicesBillingBtn from './ServicesBillingBtn.vue';
import ServicesFilters from './ServicesFilters.vue';
import Switcher from "@components/Form/Switcher.vue"
import Icon from "@icons/Icon.vue"
import { Bill } from "@icons/appFabric/icons"

import api from '@components/Portal/api'

import serviceConfig from "./serviceConfig"

import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()

import { Timestamp } from "@SyoLab/date-time"
import { toDbServiceObject, serviceHasChanges } from './components/utils'

export default {
   name: 'Services',
   components: { ServicesAddDropdown, ServicesAddDialog, ServicesEditDialog, ServicesGrid, ServicesBillingBtn, ServicesFilters, Switcher, Icon },
   props: {
      mode: { required: false, default: 'current' },
      apiUrl: { required: true },
      assignedTo: { required: false },
      customer_id: { required: false },
      customerName: { required: false },
      notBillable: { required: false },
      showCustomer: { required: false, default: true },
      showBilling: { required: false, default: true },
      admin: { required: false, default: false }
   },
   data() {
      return {
         billingPdfUrl: "api/billing/bill/pdf/",
         services: [],
         selectedServices: [],
         selectedCustomer_id: null,
         selectable: false,
         serviceGridFilters: [],
         serviceGridShowCustomer: this.showCustomer,
         serviceGridShowProjects: this.showCustomer,
         serviceGridTaskTotalCHF: false,
         // config data
         employees: [],
         workTypes: [],
         // sync
         syncTimeOut: 30000,
         setTimeOutId: null,
         lastSync: null,

      }
   },
   static: {
      iconBill: Bill,
   },
   methods: {
      async init(initServiceConfig = false) {

         // get config data in first init
         if (initServiceConfig) {
            let config = await api.get(`${this.apiUrl}/servicesConfig`)
            this.employees = config.employees.map(e => {
               e.fullName = `${e.firstName} ${e.lastName}`
               return e
            })
            this.workTypes = config.workTypes
         }

         if (this.mode == 'billed') {
            this.serviceGridShowCustomer = this.customer_id ? false : true
            this.serviceGridShowProjects = false
         } else {
            this.serviceGridShowCustomer = this.customer_id ? false : true
            this.serviceGridShowProjects = true
         }
         this.serviceGridFilters = []
         this.getData()

      },
      async getData() {
         this.services = []
         this.$emit('services', this.services)

         // undefined -> search
         // null -> do no search
         if (this.customer_id === null) return

         // services app
         this.services = await api.post(`${this.apiUrl}/services`, {
            mode: this.mode,
            assignedTo: this.assignedTo,
            customer_id: this.customer_id,
         })
         this.syncData()
         this.$emit('services', this.services)
      },
      async syncData() {

         if (!this.lastSync) {
            this.lastSync = Timestamp();
            // setup next call
            this.setTimeOutId = setTimeout(() => {
               this.syncData()
            }, this.syncTimeOut)
            return
         }


         let lastSync = Timestamp()
         // services app
         let updates = await api.post(`${this.apiUrl}/services`, {
            mode: this.mode,
            assignedTo: this.assignedTo,
            customer_id: this.customer_id,
            lastSync: this.lastSync
         })
         updates.forEach(update => {
            let service = this.services.find(s => s._id == update._id)
            if (service) {
               for (const key in service) {
                  // compare array
                  if (Array.isArray(service[key])) {
                     if (JSON.stringify(update[key]) != JSON.stringify(service[key])) {
                        this.$set(service, key, update[key])
                     }
                     // compare object
                  } else if (update[key] != service[key]) {
                     this.$set(service, key, update[key])
                  }
               }
            } else {
               this.services.push(update)
            }
         })
         this.lastSync = lastSync
         // setup next call
         clearTimeout(this.setTimeOutId)
         this.setTimeOutId = setTimeout(() => {
            this.syncData()
         }, this.syncTimeOut)
         this.$emit('servicesUpdate', this.services)
      },
      async onUpdate(service) {

         if (!service) return

         service = toDbServiceObject(service)
         this.updateTimeStamp(service)
         let index = this.services.findIndex(s => s._id == service._id)
         if (!serviceHasChanges(service, this.services[index])) {
            return
         }

         let updateProjectName = null

         if (index > -1) {
            if (service.type == 'project' && service.projectName != this.services[index].projectName) {
               updateProjectName = service.projectName
            }
            this.$set(this.services, index, service)
         } else {
            this.services.push(service)
         }
         await api.put(`${this.config.apiUrl}/services`, service)
         this.$emit('update')

         if (updateProjectName) {
            await api.post(`${this.config.apiUrl}/projectNameUpdate`, {
               project_id: service.project_id,
               projectName: updateProjectName,
               timeStamp: Timestamp(),
               userId: portalState.user.userId,
            })
         }

      },
      async onDelete(service) {
         let idx = this.services.findIndex(s => s._id == service._id)
         if (idx > -1) {
            this.services.splice(idx, 1)
            await api.delete(`${this.config.apiUrl}/services`, toDbServiceObject(service))
            this.$emit('update')
         }

      },
      onAddService(data) {
         if (this.customer_id) data.customer_id = this.customer_id
         if (this.customerName) data.customerName = this.customerName
         if (this.notBillable) data.notBillable = this.notBillable
         this.$refs.ServiceAddDialog.addService(data)
      },
      onAddServiceType(data) {
         this.$refs.ServiceAddDropdown.addServiceType(data)
      },
      onEdit(item) {
         if (!item) return
         // new service from ServicesAddDialog
         if (item._isNew) {
            this.$refs.ServiceEditDialog.editService = item
            return
         }
         // existing service
         let service = this.services.find(s => s._id == item._id)
         this.$refs.ServiceEditDialog.editService = service
      },
      onSelect(select) {
         this.selectedServices = select.selectedServices
         this.selectedCustomer_id = select.selectedCustomer_id
      },
      onServiceGridShowTaskTotal(value) {
         this.serviceGridTaskTotalCHF = value
      },
      onEmit() {
         let data = [...arguments]
         this.$emit(data[0], ...data.slice(1))
      },
      async onBchReload(id) {
         let res = await await api.get(`${this.config.apiUrl}/bch/orderUpsert/${id}`)
         let updated = res.value || null
         if (updated) {
            let idx = this.services.findIndex(s => s._id == updated._id)
            if (idx > -1) {
               this.$set(this.services, idx, updated)
            } else {
               console.log('document not found, inserting new document')
               this.services.push(updated)
            }
         }

      },
      updateTimeStamp(record) {
         record.timeStamp = Timestamp()
         record.userId = portalState.user.userId
      },
      onBilledSwitcherInput(value) {
         value = !value
         let item = this.serviceGridFilters.find(filter => filter.type == 'showBilled')
         if (item && value) {
            item.value = value
         } else if (item && !value) {
            let idx = this.serviceGridFilters.findIndex(filter => filter.type == 'showBilled')
            this.serviceGridFilters.splice(idx, 1)
         } else if (!item && value) {
            this.serviceGridFilters.push({ type: 'showBilled', value: value })
         }
      },
   },
   computed: {
      style() {
         return {
            ['--projectBg']: '#f5e685',
            //['--projectBorderColor']: '#5d5d5d',
            ['--bgColorNew']: '#B1D3EF',
            ['--bgColorWait']: '#B1D3EF',
            ['--bgColorWIP']: '#B1D3EF',
            ['--bgColorDone']: '#B1D3EF',
            ['--bgColorBilled']: '#00326d',

            //['--taskBg']: '#B1D3EF',
         }
      },
      showServiceBillingBtn() {
         if (this.services.length == 0) return false
         if (this.config.mode == 'myTasks') return false
         if (this.config.showBilling === false) return false
         return this.config.mode != 'billed'
      },
      showBilledSwitcher() {
         if (this.config.mode == 'myTasks') return false
         if (this.config.showBilling === false) return false
         return this.config.mode != 'billed'
      },
      billedSwitcherValue() {
         let value = this.serviceGridFilters.find(filter => filter.type == 'showBilled')?.value
         return !value
      },
      showAddService() {
         return this.config.mode != 'billed'
      },
      showTaskTotalSwitcher() {
         if (this.config.showBilling === false) return false
         return this.config.mode != 'billed'
      },
      config() {
         return {
            admin: this.admin,
            employee_id: portalState.userConfig.employee,
            employees: this.employees,
            workTypes: this.workTypes,
            defaultHourlyRate: 155,
            defaultServiceColor: 'grey',
            businessDomains: [
               { key: 'web', value: 'Webagentur' },
               { key: 'webApp', value: 'Webapplikationen' },
               { key: 'onlineMarketing', value: 'Online Marketing' },
               { key: 'bch', value: 'Bildung' },
               { key: 'digitalHR', value: 'Digital HR' },
            ],
            addServiceList: serviceConfig,
            apiUrl: this.apiUrl,
            mode: this.mode || 'current',
            billingPdfUrl: this.billingPdfUrl,
            showBilling: this.showBilling,
         }
      },
      toggleItems() {
         let toggleItems = []
         // toggle is only available in myTasks
         if (this.config.mode != 'myTasks') return toggleItems
         // find toggleItems
         this.services.forEach(service => {
            if (service.timeEntrys) {
               service.timeEntrys.forEach(timeEntry => {
                  if (timeEntry.from && !timeEntry.to) {
                     toggleItems.push(timeEntry)
                  }
               })
            }
         })

         return toggleItems
      }
   },
   watch: {
      customer_id(val) {
         this.getData()
         this.lastCustomer_id = val
      },
      customerName(val) {
         this.lastCustomerName = val
      },
      mode() {
         this.init()
      }

   },
   mounted() {
      this.init(true)
   },
   beforeDestroy() {
      if (this.setTimeOutId) {
         clearTimeout(this.setTimeOutId)
      }
   }

}
</script>

<style scoped>
.Services {
   display: inline-flex;
   flex-direction: column;
   row-gap: 25px;
   --borderColor: #F0F1F2;
   --borderLeftColor: #589cf9;
   --rowHeight: 34px;
   --rowBgColor: #f4f6f8;
}

.action {
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: relative;
}

.middle {
   display: flex;
   column-gap: 10px;
}
</style>