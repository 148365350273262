<template>
   <div class="DomainForwardingSubscriptionGrid">
      <div class="left">
         <div class="row">
            <div class="btn text"
               @click="onCreateSubscriptionShow">Neues Abo</div>
         </div>
         <div class="grid">
            <div class="header status"></div>
            <div class="header customer">Kunde</div>
            <div class="header domain">Domain</div>
            <div class="header since">Erstellt</div>
            <div class="header monthly">Monatlich</div>
            <div class="header yearly">Jährlich</div>
            <div class="header startDate">Beginn</div>
            <div class="header endDate">Ende</div>
            <div class="header action">Aktion</div>
            <div v-for="subscription in subscriptions"
               class="record"
               :class="{ active: activeSubscription?._id == subscription._id }"
               :Key="subscription._id">
               <div class="status">
                  <Icon v-if="subscription.terminated"
                     :icon="$static.iconCancel" />
                  <Icon v-else
                     :icon="$static.iconRenew" />
               </div>
               <div class="customer"
                  @click="onRecordClick(subscription)">{{ subscription.customerName }}</div>
               <div class="domain"
                  @click="onRecordClick(subscription)">{{ subscription.domain }}</div>
               <div class="since"
                  @click="onRecordClick(subscription)">{{ toEuDate(subscription.since) }}</div>
               <div class="monthly"
                  @click="onRecordClick(subscription)">{{ subscription.monthly }}</div>
               <div class="yearly"
                  @click="onRecordClick(subscription)">{{ subscription.yearly }}</div>
               <div class="startDate"
                  @click="onRecordClick(subscription)">{{ toEuDate(subscription.startDate) }}</div>
               <div class="endDate"
                  @click="onRecordClick(subscription)">{{ toEuDate(subscription.endDate) }}</div>
               <div class="action">
                  <MenuMore class="small"
                     orientation="right">
                     <div class="terminate"
                        @click="onSubscriptionTerminate(subscription)">
                        <Icon :icon="$static.iconCancel" />Beenden
                     </div>
                     <div v-if="showMenuTerminate(subscription)"
                        class="delete"
                        @click="onSubscriptionDelete(subscription)">
                        <Icon :icon="$static.iconDelete" />Löschen
                     </div>
                  </MenuMore>
               </div>
            </div>
         </div>
      </div>
      <div class="right">
         <div v-if="activeSubscription"
            class="activeSubscription">
            <div class="customer">{{ activeSubscription.customerName }}</div>
            <div class="subscription">{{ config.name }}</div>
            <div class="row gap">
               <div class="instance">{{ activeSubscription.instance }}</div>
               <div class="version">{{ activeSubscription.typo3Version }}</div>
            </div>
            <div class="comment">
               <Textarea placeholder="Kommentar"
                  :value="activeSubscription.comment" />
            </div>
         </div>
      </div>

      <Dialog v-if="createSubscription"
         transferTo="modal"
         closeBtn="true"
         top="150px"
         class="blue"
         header="Neues Hosting Abo"
         @outsideClick="createSubscription = false"
         @close="createSubscription = false">
         <DomainForwardingCreateSubscription :subscription="createSubscription"
            :instances="subscriptionsInstances"
            @save="onCreateSubscriptionSave" />
      </Dialog>
   </div>
</template>

<script>
import Dialog from '@components/Dialog/Dialog.vue'
import Button from '@components/Form/Button.vue'

import MenuMore from '@components/Navigation/MenuMore.vue'
import { Day } from '@SyoLab/date-time'
import api from '@components/Portal/api'
import Icon from "@icons/Icon.vue"
import { CirclePause, SyncOccurence, Delete } from "@icons/appFabric/icons"
import portalStateP from '@components/Portal/portalState';
const portalState = portalStateP()
import Textarea from '@components/Form/Textarea.vue';
import DomainForwardingCreateSubscription from './DomainForwardingCreateSubscription.vue'
import { createDomainForwardingSubscription } from '../subscriptions.js'


export default {
   name: 'DomainForwardingSubscriptionGrid',
   components: { Dialog, Button, MenuMore, Icon, Textarea, DomainForwardingCreateSubscription },
   props: {
      config: { required: true },
      instances: { required: true },
   },
   data() {
      return {
         createSubscription: null,
         subscriptions: [],
         activeSubscription: null,
      }
   },
   static: {
      iconCancel: CirclePause,
      iconRenew: SyncOccurence,
      iconDelete: Delete,
   },
   methods: {
      async getData() {
         this.subscriptions = await api.get(`subscriptions/subscriptions/${this.config.subscriptionType}`)
      },
      onCreateSubscriptionShow() {
         this.createSubscription = createDomainForwardingSubscription({
            name: this.config.name,
            yearly: this.config.yearly,
            monthly: this.config.monthly
         })
      },

      onCreateSubscriptionSave() {
         let subscription = this.createSubscription
         this.subscriptions.push(subscription)
         this.createSubscription = null
         api.put('subscriptions/subscription', subscription)
      },
      toEuDate(date) {
         if (!date) return null
         return new Day(date).toEuDate()
      },
      onSubscriptionTerminate(subscription) {
         subscription.terminated = !subscription.terminated
         api.post('subscriptions/subscription', subscription)
      },
      onSubscriptionDelete(subscription) {
         let idx = this.subscriptions.findIndex(s => s._id == subscription._id)
         this.subscriptions.splice(idx, 1)
         api.delete('subscriptions/subscription', subscription)
      },
      showMenuTerminate(subscription) {
         let diff = new Day().diff(subscription.createdTimeStamp)
         return diff <= 1
      },
      onRecordClick(record) {
         //this.activeSubscription = record
      },
      getInstance(_id) {
         if (!_id) return
         return this.instances.find(instance => instance._id == _id).name
      }
   },
   computed: {
      subscriptionsInstances() {
         return this.instances.filter(item => {
            if (!item.subscriptions) return false
            return item.subscriptions.includes(this.config.subscriptionType)
         })
      },
   },
   created() {
      this.getData()
   }
}
</script>

<style scoped>
.DomainForwardingSubscriptionGrid {
   display: flex;
   padding-top: 25px;

   .right {
      padding-top: 40px;
      padding-left: 75px;
   }
}

.grid {
   display: grid;
   grid-template-columns: [status] 55px [customer] 250px [domain] 250px [since] 115px [monthly] max-content [yearly] max-content [startDate] max-content [endDate] max-content [action] 75px;
   ;
   margin-top: 5px;

   .header {
      padding: 5px 10px 13px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
   }

   .record {
      display: contents;

      >div {
         padding: 7px;
         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
         display: flex;
         align-items: center;
         white-space: nowrap;
         overflow: ellipsis;
      }

      .status {
         grid-column: status;
         justify-content: center;
         cursor: pointer;
      }

      .customer {
         grid-column: customer;
         cursor: pointer;
      }

      .domain {
         grid-column: domain;
         cursor: pointer;
      }

      .instance {
         grid-column: instance;
         cursor: pointer;
      }

      .version {
         grid-column: version;
         justify-content: center;
         cursor: pointer;
      }

      .php {
         grid-column: php;
         justify-content: center;
         cursor: pointer;
      }

      .since {
         grid-column: since;
         cursor: pointer;
      }

      .monthly {
         grid-column: monthly;
         justify-content: center;
         cursor: pointer;
      }

      .yearly {
         grid-column: yearly;
         justify-content: center;
         cursor: pointer;
      }

      .startDate {
         grid-column: startDate;
         cursor: pointer;
      }

      .endDate {
         grid-column: endDate;
         cursor: pointer;
      }

      .action {
         grid-column: action;
         justify-content: center;
      }

      .action .delete,
      .action .terminate {
         display: flex;
         align-items: center;
         column-gap: 5px;
      }

   }

   .record.active>div {
      background-color: var(--activeHover);
   }

}







.activeSubscription {
   border: 1px solid lightgrey;
   border-radius: 5px;
   padding: 15px;

   .customer {
      font-weight: 300;
      font-size: 24px;
   }

   .subscription {
      font-weight: 500;
   }

}
</style>