var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BillingList"},[_c('div',{staticClass:"filter"},[_c('DatePicker',{staticClass:"noNotification",attrs:{"label":"Von","options":{
            unit: 'month'
         }},on:{"input":_vm.onStartMonthChange},model:{value:(_vm.startMonth),callback:function ($$v) {_vm.startMonth=$$v},expression:"startMonth"}}),_c('DatePicker',{staticClass:"noNotification",attrs:{"label":"Bis","options":{
            unit: 'month'
         }},on:{"onEndMonthChange":_vm.onEndMonthChange},model:{value:(_vm.endMonth),callback:function ($$v) {_vm.endMonth=$$v},expression:"endMonth"}}),_c('Dropdown',{staticClass:"noNotification",attrs:{"label":"Suche","placeholder":"Rechnungsnummer","width":"330px","autocomplete":_vm.onBillNoSearch,"readonly":_vm.loading,"options":{
            autocomplete: true,
            autocompleteFnOnInit: false,
            listItemKey: 'billNo',
            listItemValueKey: 'text',
            autocompleteClearable: true,
            triangle: false
         }},on:{"input":_vm.onBillNoSearchSelect}}),(_vm.loading)?_c('Loader'):_vm._e()],1),(!_vm.firstLoad)?[(_vm.bills.length > 0)?[(_vm.bills.length > 1)?_c('BillingListStats',{attrs:{"bills":_vm.bills,"startMonth":_vm.startMonth,"endMonth":_vm.endMonth}}):_vm._e(),_c('BillingGrid',{attrs:{"bills":_vm.bills,"showCustomerId":_vm.showCustomerId,"pdfUrl":"/api/billing/bill/pdf/"},on:{"deleteBill":function($event){_vm.showDeleteDialog = $event}}})]:[_c('div',{staticClass:"noBills"},[_vm._v("keine Rechnungen")])]]:_vm._e(),(_vm.showDeleteDialog)?_c('Dialog',{attrs:{"top":"250px","transferTo":"modal","header":"Rechnung löschen"},on:{"close":function($event){_vm.showDeleteDialog = false},"outsideClick":function($event){_vm.showDeleteDialog = false}}},[_c('div',{staticClass:"dlg"},[_c('div',{staticClass:"warning"},[_vm._v(" Möchtest du die Rechnung "),_c('span',[_vm._v(_vm._s(_vm.showDeleteDialog.customerName)+" "+_vm._s(_vm.showDeleteDialog.billNo))]),_vm._v(" löschen? ")]),_c('div',{staticClass:"info"},[_vm._v("Mit einem Click auf Löschen werden:")]),_c('ul',{staticClass:"apleona"},[_c('li',[_vm._v("die Rechnung gelöscht")]),_c('li',[_vm._v("die verrechneten Leistungen auf \"Fertig\" zurückgesetzt")])]),_c('div',{staticClass:"action"},[_c('Button',{on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v("Abbrechen")]),_c('Button',{staticClass:"red",on:{"click":function($event){return _vm.onDeleteBill()}}},[_vm._v("Löschen")])],1)])]):_vm._e(),(_vm.showServicesDialog)?_c('Dialog',{attrs:{"transferTo":"modal","header":"Leistungen"},on:{"close":function($event){_vm.showServicesDialog = false},"outsideClick":function($event){_vm.showServicesDialog = false}}},[_c('ServicesGrid',{attrs:{"services":_vm.services}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }